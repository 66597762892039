import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  Flex,
  Tooltip,
  // PopoverBody,
  PopoverFooter,
  ButtonGroup,
  Button,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { AiOutlineMedicineBox } from 'react-icons/ai'

const StyledLink = styled(Link)`
  transition: color 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.blue500};
  }
`

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledName = styled.div`
  ${({ theme }) => theme.utils.ellipsis};
`
const StyledUsername = styled.div`
  color: ${({ theme }) => theme.colors.gray500};
  font-size: 12px;
`

export const IdCell = ({ row: { original } }) => {
  return <StyledLink to={`/users/${original?.id}`}>{original?.id}</StyledLink>
}

export const UserCell = ({ row: { original } }) => {
  const { firstName, lastName, username, id } = original || {}

  return (
    <UserContainer>
      <Tooltip hasArrow label={`${firstName} ${lastName || ''}`}>
        <StyledLink to={`/users/${id}`}>
          <StyledName>{`${firstName} ${lastName || ''}`}</StyledName>
        </StyledLink>
      </Tooltip>
      {username && <StyledUsername>{username}</StyledUsername>}
    </UserContainer>
  )
}

const TimeCell = styled.div`
  font-size: 14px;
  text-align: center;
`

export const DateCell = props => {
  const { row: { original: { lastLoginAt, memberUntil } = {} } = {} } = props

  return (
    <Flex direction="column">
      <TimeCell>
        {lastLoginAt
          ? format(new Date(lastLoginAt), 'MM/dd/yyyy hh:mm aa')
          : '-'}
      </TimeCell>
      <TimeCell>
        {memberUntil
          ? format(new Date(memberUntil), 'MM/dd/yyyy hh:mm aa')
          : '-'}
      </TimeCell>
    </Flex>
  )
}

const StyledStatus = styled.div`
  font-weight: 600;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
  color: ${({ color, theme }) => theme.colors[color]};
  background-color: ${({ color, theme }) =>
    theme.getAlphaRgb(theme.colors[color], 0.08)};
  user-select: none;
`

export const StatusCell = props => {
  const {
    row: {
      original: { registeredPhysician, registeredPhysicianVerified } = {},
    } = {},
  } = props

  return (
    <Flex d="column" align="center" justify="center">
      <StyledStatus
        color={
          registeredPhysician
            ? registeredPhysicianVerified
              ? 'green400'
              : 'yellow400'
            : 'gray400'
        }
      >
        {registeredPhysician ? 'Yes' : 'No'}&nbsp;/&nbsp;
        {registeredPhysicianVerified ? 'Verified' : 'Unverified'}
      </StyledStatus>
    </Flex>
  )
}

export const UpdateStatusButton = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ReviewIcon = styled(AiOutlineMedicineBox)``

// const Label = styled.div`
//   font-weight: 500;
//   margin-bottom: 5px;
//   ${({ theme }) => theme.font.weight.bold};
//   ${({ disabled }) =>
//     disabled &&
//     css`
//       color: ${({ theme }) => theme.colors.gray200};
//     `}
// `

// const ReviewContainer = styled.div`
//   display: flex;
//   flex-direction: column;
// `

export const ReviewForm = ({ original, updateStatus, close }) => {
  const {
    // registeredPhysician: _status,
    registeredPhysicianVerified: _verified,
  } = original
  const [updating, setUpdating] = useState(false)

  // const willApprove = !_status || (_status && !_verified)
  const willApprove = !_verified

  return (
    <>
      {/* <PopoverBody>
        <ReviewContainer>
          <Label>Review this user as physician</Label>
        </ReviewContainer>
      </PopoverBody> */}
      <PopoverFooter d="flex" justifyContent="flex-end">
        <ButtonGroup size="sm">
          <Button variant="outline" onClick={close}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            isLoading={updating}
            onClick={() => {
              setUpdating(true)
              updateStatus({
                ...original,
                // registeredPhysician: willApprove,
                registeredPhysicianVerified: true,
              }).then(() => {
                close()
                setUpdating(false)
              })
            }}
          >
            {willApprove ? 'Verify as physician' : 'Remove physician review'}
          </Button>
        </ButtonGroup>
      </PopoverFooter>
    </>
  )
}
