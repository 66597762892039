import React, { useState, useMemo } from 'react'
import * as L from 'layouts/ListLayout'
import { useNavigate } from 'react-router-dom'
import { doLoad } from '../modules/datasource'
import { getColumns } from '../modules/constants'

const defaultProps = {}
const propTypes = {}

export const ArticleReportList = () => {
  const [listState, setListState] = useState({})
  const navigate = useNavigate()

  const { columnsConfig } = useMemo(() => {
    return getColumns()
  }, [])

  const toolbarConfig = useMemo(() => {
    return {
      filters: [
        {
          name: 'showClosed',
          type: 'checkbox',
          label: 'Show closed',
        },
      ],
    }
  }, [])

  const load = async loadProps => {
    const tableState = await doLoad(loadProps)
    setListState(tableState)
    return tableState
  }

  return (
    <L.Container>
      <L.Content>
        <L.Panel>
          <L.Table
            fetchData={load}
            columns={columnsConfig}
            toolbarConfig={toolbarConfig}
            actions={{
              detailClick: ({ original }) =>
                navigate(`/article-reports/${original?.id}`),
            }}
            {...listState}
          />
        </L.Panel>
      </L.Content>
    </L.Container>
  )
}

ArticleReportList.defaultProps = defaultProps
ArticleReportList.propTypes = propTypes

export default ArticleReportList
