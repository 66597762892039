import * as R from 'ramda'
import api, { getPaginatedList } from 'shared/api'

export const doLoad = async props => {
  return await getPaginatedList('/admin/articles', props)
}

export const doUpdateArticleStatus = ({ articleId, status, scheduleDate }) => {
  return api.put(`/admin/articles/${articleId}/review`, {
    status,
    scheduleDate: scheduleDate ? new Date(scheduleDate).toISOString() : null,
  })
}

export const doLoadUsers = async props => {
  const { list } = await getPaginatedList('/admin/users', props)

  const listWithAllUsernames = R.filter(({ username }) => !!username, list)

  return {
    list: listWithAllUsernames,
    total: listWithAllUsernames?.length,
  }
}

export const changeUser = async ({ article, user }) => {
  return api.put(`/admin/articles/${article?.articleId}/change-user`, {
    userId: user?.id,
  })
}

export const doUpdateArticleFreeStatus = ({ articleId, isFree }) => {
  return api.put(`/admin/articles/${articleId}/set-free-status`, {
    isFree: isFree === 'true',
  })
}

export const deleteArticle = async ({ articleId, values }) => {
  return api.delete(`/admin/articles/${articleId}`, { data: values })
}
