import React, { createContext, useContext, useState } from 'react'

const MenuBar = createContext()

export function MenuBarProvider({ children }) {
  const [actions, setActions] = useState([])

  return (
    <MenuBar.Provider value={{ actions, setActions }}>
      {children}
    </MenuBar.Provider>
  )
}

export function useMenuBar() {
  return useContext(MenuBar)
}
