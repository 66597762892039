import { css } from 'styled-components'

const family = {
  heading: css`
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  `,
  body: css`
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  `,
  mono: css`
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
      'Courier New', monospace;
  `,
}
const weight = {
  hairline: css`
    font-weight: 100;
  `,
  thin: css`
    font-weight: 200;
  `,
  light: css`
    font-weight: 300;
  `,
  normal: css`
    font-weight: 400;
  `,
  medium: css`
    font-weight: 500;
  `,
  semibold: css`
    font-weight: 600;
  `,
  bold: css`
    font-weight: 700;
  `,
  extrabold: css`
    font-weight: 800;
  `,
  black: css`
    font-weight: 900;
  `,
}

export const FontStyles = {
  family,
  weight,
}
