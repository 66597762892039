const size = {
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  xl2: '96em',
}

export const media = {
  sm: `@media screen and (min-width: ${size.sm})`,
  md: `@media screen and (min-width: ${size.md})`,
  lg: `@media screen and (min-width: ${size.lg})`,
  xl: `@media screen and (min-width: ${size.xl})`,
  xl2: `@media screen and (min-width: ${size.xl2})`,
}
