import React, { useState, useMemo } from 'react'
import slugfy from 'slug'
import { useDisclosure } from '@chakra-ui/react'
import * as L from 'layouts/ListLayout'
import { VscOpenPreview } from 'react-icons/vsc'

import { doLoad } from '../modules/datasource'
import { getColumns } from '../modules/constants'

import RemoveArticle from './RemoveGroupForm'

const defaultProps = {}
const propTypes = {}

const modalMap = {
  deleteGroup: RemoveArticle,
}

const host = process.env.REACT_APP_BASE_URL

export const GroupsList = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [modal, setModal] = useState(null)
  const [groupToDelete, setGroupToDelete] = useState(null)
  const [listState, setListState] = useState({})

  const { columnsConfig } = useMemo(() => {
    return getColumns()
  }, [])

  const toolbarConfig = useMemo(() => {
    return {}
  }, [])

  const load = async loadProps => {
    const tableState = await doLoad(loadProps)
    setListState(tableState)
    return tableState
  }

  const openModal = m => {
    setModal(m)
    onOpen()
  }

  const closeModal = () => {
    onClose()
    setModal(null)
  }

  const modalConfig = {
    onClose: closeModal,
    isOpen,
    setListState,
    groupToDelete,
  }

  const ModalComponent = modalMap[modal]

  return (
    <L.Container>
      <L.Content>
        <L.Panel>
          <L.Table
            fetchData={load}
            columns={columnsConfig}
            toolbarConfig={toolbarConfig}
            actions={{
              preview: {
                onClick: ({ original }) => {
                  const { name } = original
                  const slug = slugfy(name)

                  window.open(
                    `${host}/groups/${original?.id}/${slug}`,
                    '_blank'
                  )
                },
                tooltipConfig: {
                  label: 'Preview',
                },
                Icon: () => <VscOpenPreview />,
                disabled: ({ original }) => !original.name,
              },
              deleteClick: ({ original }) => {
                openModal('deleteGroup')
                setGroupToDelete(original)
              },
            }}
            {...listState}
          />
        </L.Panel>
      </L.Content>
      {isOpen && <ModalComponent {...modalConfig} />}
    </L.Container>
  )
}

GroupsList.defaultProps = defaultProps
GroupsList.propTypes = propTypes

export default GroupsList
