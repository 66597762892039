import * as R from 'ramda'
import React from 'react'
import styled from 'styled-components'

const ProfilceCellContainer = styled.div`
  display: flex;
`
const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  max-height: 30px;
  max-width: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.blue400};
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
`
const UserAvatar = styled.img`
  width: 100%;
  height: 100%;
`
const UserDesc = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`
const UserInfo = styled.div`
  span {
    color: ${({ theme }) => theme.colors.gray500};
    font-size: 12px;
  }
`

export const CustomCell = props => {
  const { row: { original: { profile } = {} } = {} } = props

  return (
    <ProfilceCellContainer>
      <AvatarWrapper>
        {profile?.profileImage ? (
          <UserAvatar src={profile?.profileImage} />
        ) : (
          R.head(profile?.firstName)
        )}
      </AvatarWrapper>
      <UserDesc>
        <UserInfo>
          {profile?.firstName} {profile?.lastName}{' '}
          <span>{profile?.username}</span>
        </UserInfo>
        <UserInfo>
          <span>
            {profile?.company} | {profile?.position}
          </span>
        </UserInfo>
      </UserDesc>
    </ProfilceCellContainer>
  )
}
