import * as R from 'ramda'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { debounce } from 'lodash'
import {
  Box,
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputLeftAddon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { FiFilter } from 'react-icons/fi'
import { ImSearch } from 'react-icons/im'

import TableFilters from './Filters'
import { updateFilter } from './Utils'

const defaultProps = {}
const propTypes = {}

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
`
const Actions = styled.div`
  display: flex;
  margin-left: auto;
`

export const Toolbar = ({ config }) => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const {
    addButton,
    searchProps = {},
    filters = [],
    setFilters,
    tableFilters,
  } = config || {}

  const hasFilters = !R.isEmpty(filters)

  useEffect(() => {
    hasFilters &&
      filters.map(
        ({ name, defaultValue }) =>
          !R.isNil(defaultValue) &&
          setFilters(x => updateFilter(name, defaultValue, x))
      )
  }, [filters])

  const { name } = searchProps
  const debounceFetch =
    name &&
    debounce(e => {
      setFilters(x => updateFilter(name, e?.target?.value, x))
    }, 800)

  const filterCount = R.reject(
    ({ name, value }) => !value || name === searchProps?.name,
    tableFilters
  ).length

  if (R.isEmpty(config)) {
    return null
  }

  return (
    <Container>
      {!R.isEmpty(searchProps) && (
        <InputGroup>
          <InputLeftAddon>
            <ImSearch />
          </InputLeftAddon>
          <Input
            placeholder="Search"
            onChange={debounceFetch}
            w="50%"
            {...searchProps}
          />
        </InputGroup>
      )}
      <Actions>
        <ButtonGroup>
          {hasFilters && (
            <Popover
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              placement="left-start"
            >
              <PopoverTrigger>
                <Button>
                  {filterCount ? (
                    <Box
                      w={19}
                      h={19}
                      background="blue.500"
                      color="white"
                      borderRadius="50%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      marginRight={2}
                      fontSize={12}
                    >
                      {filterCount}
                    </Box>
                  ) : null}
                  Filter <FiFilter style={{ marginLeft: 10 }} />
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverHeader>Filters</PopoverHeader>
                <PopoverCloseButton />
                <TableFilters key={isOpen} close={onClose} {...config} />
                {/* <PopoverFooter>This is the footer</PopoverFooter> */}
              </PopoverContent>
            </Popover>
          )}
          {addButton && (
            <Button
              {...addButton}
              onClick={() => {
                const { onClick } = addButton
                onClick && onClick()
              }}
            >
              {addButton?.label || 'Create'}
            </Button>
          )}
        </ButtonGroup>
      </Actions>
    </Container>
  )
}

Toolbar.defaultProps = defaultProps
Toolbar.propTypes = propTypes

export default Toolbar
