import React from 'react'
import { Navigate } from 'react-router-dom'
// import { Button } from '@chakra-ui/react'
// import api from 'shared/api'

const defaultProps = {}
const propTypes = {}

export const Home = () => {
  return <Navigate to="/articles" />
  // return (
  //   <div>
  //     <Button onClick={() => api.get('/admin/users')}>get</Button>
  //   </div>
  // )
}

Home.defaultProps = defaultProps
Home.propTypes = propTypes

export default Home
