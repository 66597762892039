import * as R from 'ramda'
import React from 'react'
import styled, { css } from 'styled-components'
import { useColorModeValue, Tooltip } from '@chakra-ui/react'
import CloseIcon from './assets/CloseIcon'
import DeleteIcon from './assets/DeleteIcon'
import EditIcon from './assets/EditIcon'
import ExpandIcon from './assets/ExpandIcon'

const DetailContainer = styled.div`
  height: 33px;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
  user-select: none;
  &:hover {
    background: ${({ theme, hoverColor }) => theme.chakra(hoverColor)};
    path {
      fill: ${({ theme }) => theme.colors.orange};
    }
  }
  ${({ disabled }) =>
    disabled &&
    css`
      path {
        fill: ${({ theme, disabledColor }) => theme.chakra(disabledColor)};
      }
      pointer-events: none;
    `}
`

const mapTypes = {
  detailClick: {
    Icon: EditIcon,
    tooltipConfig: {
      label: 'Edit',
    },
  },
  deleteClick: {
    Icon: DeleteIcon,
    tooltipConfig: {
      label: 'Remove',
    },
  },
}

const ExpandCell = styled.span``

const ExpandButtonStyles = css`
  width: 28px;
  height: 28px;
  background: ${({ theme }) => theme.colors.background};
  border: solid 1px ${({ theme }) => theme.colors.blue100};
  color: ${({ theme }) => theme.colors.gray};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  margin-bottom: 0;
  transition: all 0.2s;
  &:hover {
    background: ${({ theme }) => theme.colors.blue50};
    border-color: ${({ theme }) => theme.colors.blue300};
    color: ${({ theme }) => theme.colors.blue400};
  }
`

const CloseStyles = css`
  background: transparent;
  border-color: transparent;
  font-size: 18px;
  margin: 20px 0;
`
const StyledExpand = styled.div`
  ${ExpandButtonStyles}
  ${({ expanded }) => expanded && CloseStyles};
`

export const getExpandAction = enable =>
  enable
    ? [
        {
          Header: () => null,
          id: 'expander',
          width: 23,
          maxWidth: 40,
          style: {
            padding: 0,
            paddingRight: 5,
            justifyContent: 'flex-end',
            minWidth: 33,
            maxWidth: 40,
            marginLeft: 5,
          },
          Cell: ({ row }) => {
            const cellProps = row.getToggleRowExpandedProps()
            const { onClick, ...rest } = cellProps
            const clickEvent = e => {
              onClick(e)
            }

            return (
              <ExpandCell {...rest} onClick={clickEvent}>
                <StyledExpand expanded={row.isExpanded}>
                  {row.isExpanded ? <CloseIcon /> : <ExpandIcon />}
                </StyledExpand>
              </ExpandCell>
            )
          },
        },
      ]
    : []

const ConditionalTooltip = ({ hasTooltip, wrapper, children }) =>
  hasTooltip ? wrapper(children) : children

export const getTableActions = (actions = []) => {
  if (R.isEmpty(actions)) return []

  const tableActions = R.mapObjIndexed((action, name) => {
    const buttonConfig = mapTypes[name] || action
    const {
      onClick,
      Icon,
      disabled: customDisabled,
      tooltipConfig,
    } = buttonConfig

    return {
      Header: () => null,
      id: name,
      width: 23,
      maxWidth: 40,
      style: {
        padding: '16px 0',
        justifyContent: 'flex-end',
        minWidth: 33,
        maxWidth: 40,
      },
      disableSortBy: true,
      Cell: ({ row }) => {
        const clickEvent = () => {
          const clickEvent = onClick || action
          clickEvent(row)
        }
        const hoverColor = useColorModeValue('gray.100', 'whiteAlpha.100')
        const disabledColor = useColorModeValue('gray.300', 'whiteAlpha.300')
        const disabled = customDisabled && customDisabled(row)

        return (
          <ConditionalTooltip
            hasTooltip={!R.isEmpty(tooltipConfig)}
            wrapper={children => (
              <Tooltip hasArrow {...tooltipConfig}>
                {children}
              </Tooltip>
            )}
          >
            <DetailContainer
              hoverColor={hoverColor}
              disabledColor={disabledColor}
              disabled={disabled}
              onClick={clickEvent}
            >
              <Icon original={row?.original} />
            </DetailContainer>
          </ConditionalTooltip>
        )
      },
    }
  }, actions)

  return R.values(tableActions)
}
