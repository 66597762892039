import { format } from 'date-fns'
import { CustomCell } from '../components/StaffPickList.styles'

export const getColumns = () => {
  const COLUMNS_CONFIG = [
    {
      Header: 'Author',
      accessor: 'articleId',
      Cell: CustomCell,
    },
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Published At',
      accessor: ({ publishedAt }) =>
        publishedAt && format(new Date(publishedAt), 'MM/dd/yyyy'),
      id: 'publishedAt',
      width: 60,
    },
  ]

  return { columnsConfig: COLUMNS_CONFIG }
}
