import * as R from 'ramda'
import React from 'react'
import styled from 'styled-components'
import { Flex } from '@chakra-ui/react'

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  max-height: 45px;
  max-width: 45px;
  min-width: 45px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.blue400};
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  text-transform: uppercase;
`
const UserAvatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const UserDesc = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`
const UserInfo = styled.div`
  span {
    color: ${({ theme }) => theme.colors.gray500};
    font-size: 12px;
  }
`

export const CustomCell = props => {
  const { row: { original: { name, image } = {} } = {} } = props

  return (
    <Flex>
      <AvatarWrapper>
        {image ? <UserAvatar src={image} /> : R.head(name || 'G')}
      </AvatarWrapper>
      <UserDesc>
        <UserInfo>{name}</UserInfo>
      </UserDesc>
    </Flex>
  )
}
