import {
  CustomCell,
  StatusCell,
  DateCell,
} from '../components/ArticlesList.styles'

export const getColumns = () => {
  const COLUMNS_CONFIG = [
    {
      Header: 'Author',
      accessor: 'articleId',
      Cell: CustomCell,
      style: {
        maxWidth: 270,
        width: '100%',
      },
    },
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Published / Scheduled',
      id: 'scheduledDate',
      Cell: DateCell,
      style: {
        maxWidth: 200,
        width: '100%',
      },
    },
    {
      Header: 'Status / Free',
      id: 'status',
      accessor: ({ status }) => status || 'Pending',
      Cell: StatusCell,
      style: {
        maxWidth: 190,
        width: '100%',
      },
    },
  ]

  return { columnsConfig: COLUMNS_CONFIG }
}
