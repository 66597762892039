import * as R from 'ramda'
import React, { useState, useMemo, useRef } from 'react'
import slugfy from 'slug'
import styled from 'styled-components'
import * as L from 'layouts/ListLayout'
import { VscOpenPreview } from 'react-icons/vsc'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useToast,
} from '@chakra-ui/react'
import { doLoad, doDelete } from '../modules/datasource'
import { getColumns } from '../modules/constants'

import { DescriptionSubRender } from './AnswersList.styles'

const defaultProps = {}
const propTypes = {}

const host = process.env.REACT_APP_BASE_URL

const CommentPreview = styled.div`
  max-height: 200px;
  overflow-y: auto;
`

export const AnswersList = () => {
  const [isFetching, setIsFetching] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [delItem, setItemToDelete] = useState(null)
  const toast = useToast()

  const onClose = () => {
    setDeleteOpen(false)
    setItemToDelete(null)
  }
  const [listState, setListState] = useState({})
  const cancelRef = useRef()

  const { columnsConfig } = useMemo(() => {
    return getColumns()
  }, [])

  const load = async loadProps => {
    const tableState = await doLoad(loadProps)
    setListState(tableState)
    return tableState
  }

  const deleteComment = async () => {
    setIsFetching(true)
    try {
      await doDelete(delItem)
      onClose()
      setListState(state => {
        const { list } = state
        return {
          ...state,
          list: R.reject(R.propEq('id', delItem?.id), list),
        }
      })
    } catch (e) {
      setIsFetching(false)
      toast({
        title: e?.response?.data?.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      })
    }
  }

  return (
    <L.Container>
      <L.Content>
        <L.Panel>
          <L.Table
            fetchData={load}
            columns={columnsConfig}
            actions={{
              preview: {
                onClick: ({ original }) => {
                  const { questionId, questionTitle } = original
                  const slug = slugfy(questionTitle)
                  window.open(
                    `${host}/questions/${questionId}/${slug}`,
                    '_blank'
                  )
                },
                tooltipConfig: {
                  label: 'Preview',
                },
                Icon: () => <VscOpenPreview />,
                disabled: ({ original }) => !original.questionId,
              },
              deleteClick: ({ original }) => {
                setIsFetching(false)
                setItemToDelete(original)
                setDeleteOpen(true)
              },
            }}
            renderSubComponent={DescriptionSubRender}
            {...listState}
          />
        </L.Panel>
      </L.Content>
      <AlertDialog
        isOpen={deleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent maxWidth={670}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {'Delete answer'}
            </AlertDialogHeader>

            <AlertDialogBody>
              <div>{"Are you sure? You won't be able to undo this"}</div>
              {deleteOpen && (
                <>
                  <br />
                  <strong>Question:</strong>
                  <br />
                  <CommentPreview>{delItem?.questionTitle}</CommentPreview>
                  <br />
                  <strong>Answer:</strong>
                  <br />
                  <CommentPreview>{delItem?.text}</CommentPreview>
                </>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button disabled={isFetching} ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme={'red'}
                onClick={deleteComment}
                ml={3}
                isLoading={isFetching}
              >
                {'Delete'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </L.Container>
  )
}

AnswersList.defaultProps = defaultProps
AnswersList.propTypes = propTypes

export default AnswersList
