import * as R from 'ramda'
import React from 'react'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Tooltip, Flex, useColorModeValue } from '@chakra-ui/react'

const StyledOption = styled.div`
  position: relative;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.white200};
  border-radius: 5px;
  transition: color 0.3s;
  color: ${({ theme }) => theme.colors.white600};
  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.colors.white};
      &::after {
        content: '';
        position: absolute;
        left: 40px;
        top: 8px;
        border-right: 10px solid
          ${({ theme, afterColor }) => theme.chakra(afterColor)};
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }
    `}
`

export const MainOption = props => {
  const { title, icon, isActive, onClick } = props

  return (
    <Tooltip
      hasArrow
      label={title}
      bg="gray.300"
      color="black"
      placement="right"
    >
      <StyledOption
        onClick={onClick}
        isActive={isActive}
        afterColor={useColorModeValue('gray.100', 'blackAlpha.500')}
      >
        {icon}
      </StyledOption>
    </Tooltip>
  )
}

export const TSNTitle = styled.div`
  line-height: 60px;
  padding-left: 16px;
  ${({ theme }) => theme.font.weight.bold};
`

const StyledLink = styled.div`
  ${({ theme }) => theme.font.weight.normal}
  padding: 8px 16px;
  cursor: pointer;
  ${({ isActive, activeColor, theme }) =>
    isActive &&
    css`
      color: ${theme.chakra(activeColor)};
      ${theme.font.weight.medium};
    `};
`

const SubTitle = styled.div`
  margin: 10px 0;
  ${({ theme }) => theme.font.weight.medium};
`

const SubOption = ({ title, path }) => {
  const resolved = useResolvedPath(path)
  const match = useMatch({ path: resolved.pathname, end: false })

  return (
    <Link to={path}>
      <StyledLink
        activeColor={useColorModeValue('blue.600', 'blue.400')}
        isActive={match}
      >
        {title}
      </StyledLink>
    </Link>
  )
}

export const SubOptions = ({ routes, title }) => {
  return (
    <Flex direction="column" p="16px">
      <SubTitle>{title}</SubTitle>
      {routes &&
        !R.isEmpty(routes) &&
        routes.map(item => {
          if (item?.type === 'title') {
            return <SubTitle>{item?.title}</SubTitle>
          }
          return <SubOption {...item} key={item?.title} />
        })}
    </Flex>
  )
}
