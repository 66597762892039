import * as R from 'ramda'
import React, { useEffect, useState, useMemo } from 'react'
import * as D from 'layouts/DetailLayout'
import { useNavigate } from 'react-router-dom'
import { VscOpenPreview } from 'react-icons/vsc'
import { ImArrowRight } from 'react-icons/im'
import { IoMdRemoveCircle } from 'react-icons/io'
import { Box, Button, useColorModeValue } from '@chakra-ui/react'
import { Loading } from 'components/elements'
import { doLoad, doLoadStaffPick, doSave } from '../modules/datasource'
import { getColumns } from '../modules/constants'
import PickList from './PickList'

import * as S from './StaffPickDetail.styles'

const defaultProps = {}
const propTypes = {}

const host = process.env.REACT_APP_BASE_URL

export const Detail = () => {
  const navigate = useNavigate()
  const [isFetching, setIsFetching] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [hasFetched, setFetched] = useState(false)
  const [listState, setListState] = useState({})
  const [selectedList, setSelectedList] = useState([])
  const footerBorderColor = useColorModeValue('gray.200', 'whiteAlpha.300')
  const footerColor = useColorModeValue('white', 'gray.800')

  const { columnsConfig } = useMemo(() => {
    return getColumns()
  }, [])

  const toolbarConfig = useMemo(() => {
    return {
      searchProps: {
        name: 'title',
        placeholder: 'Article title',
      },
    }
  }, [])

  const load = async loadProps => {
    setFetched(false)
    const tableState = await doLoad(loadProps)
    setListState(tableState)
    setFetched(true)
    return tableState
  }

  const save = async () => {
    setIsSaving(true)
    try {
      await doSave({ list: R.pluck('id', selectedList) })
      navigate('/staff-pick')
    } catch {
      setIsSaving(false)
    }
  }

  const updateStatus = (id, isSelected) => {
    setListState(state => {
      const { list } = state
      const articleIndex = R.findIndex(R.propEq('articleId', Number(id)), list)
      return {
        ...state,
        list: R.adjust(
          articleIndex,
          props => ({
            ...props,
            isSelected,
          }),
          list
        ),
      }
    })
  }

  useEffect(async () => {
    const staffPickState = await doLoadStaffPick()
    setSelectedList(
      R.map(
        a => ({
          id: a?.id,
          text: a?.title,
        }),
        staffPickState?.list || []
      )
    )
    setIsFetching(false)
  }, [])

  useEffect(() => {
    if (hasFetched) {
      setListState(state => {
        const selectedIds = R.pluck('id', selectedList)

        const newList = R.map(i => {
          const { articleId } = i

          return {
            ...i,
            isSelected: R.includes(articleId, selectedIds),
          }
        }, state?.list || [])

        return {
          ...state,
          list: newList,
        }
      })
    }
  }, [hasFetched])

  return (
    <D.Container>
      <S.Content>
        <D.Panel title="Chose Staff Pick">
          {isFetching ? (
            <S.LoadingContainer>
              <Loading />
            </S.LoadingContainer>
          ) : (
            <S.OptionsTable
              fetchData={load}
              columns={columnsConfig}
              toolbarConfig={toolbarConfig}
              actions={{
                preview: {
                  onClick: ({ original }) => {
                    const { slug, username } = original
                    window.open(`${host}/p/${username}/${slug}`, '_blank')
                  },
                  Icon: () => <VscOpenPreview />,
                  disabled: ({ original }) =>
                    !original.slug || !original?.profile?.username,
                },
                pick: {
                  onClick: ({ original }) => {
                    const id = original?.articleId
                    setSelectedList(state => {
                      const shouldAdd = R.includes(id, R.pluck('id', state))
                      updateStatus(original?.articleId, !shouldAdd)
                      return !shouldAdd
                        ? R.concat([
                            {
                              id,
                              text: original?.title,
                            },
                          ])(state)
                        : R.without([
                            {
                              id,
                              text: original?.title,
                            },
                          ])(state)
                    })
                  },
                  Icon: ({ original }) =>
                    original?.isSelected ? (
                      <IoMdRemoveCircle />
                    ) : (
                      <ImArrowRight />
                    ),
                  disabled: ({ original }) =>
                    !original.slug || !original?.profile?.username,
                },
              }}
              {...listState}
            />
          )}
        </D.Panel>
        <D.Panel style={{ overflow: 'auto', maxHeight: 'calc(70vh + 114px)' }}>
          {isFetching ? (
            <S.LoadingContainer>
              <Loading />
            </S.LoadingContainer>
          ) : (
            <>
              <PickList
                list={selectedList}
                setList={setSelectedList}
                updateStatus={updateStatus}
              />
              <Box
                w="100%"
                h="60px"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                position="sticky"
                bottom={0}
                p={3}
                borderTop="1px"
                borderColor={footerBorderColor}
                backgroundColor={footerColor}
              >
                <Button
                  isLoading={isSaving}
                  disabled={isFetching}
                  onClick={save}
                >
                  Save
                </Button>
              </Box>
            </>
          )}
        </D.Panel>
      </S.Content>
    </D.Container>
  )
}

Detail.defaultProps = defaultProps
Detail.propTypes = propTypes

export default Detail
