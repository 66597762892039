import React from 'react'
import List from './components/ArticleReportList'

const Route = () => ({
  index: true,
  path: '',
  element: <List />,
})

export default Route
