import * as R from 'ramda'
import React, { useMemo } from 'react'
import { ThemeProvider } from 'styled-components'
import { chakra } from './colors'
import { chakraColors } from './chakraColors'
import { media } from './media'
import { FontStyles } from './typography'
import { Ellipsis, getAlphaRgb } from './Utils'

export const Theme = ({ children }) => {
  const themeColors = useMemo(
    () => ({
      ...chakra,
    }),
    [chakra]
  )

  const themeConfig = {
    colors: {
      ...themeColors,
    },
    getAlphaRgb,
    chakra: color => R.path(R.split('.', color), chakraColors) || color,
    font: FontStyles,
    utils: {
      ellipsis: Ellipsis,
    },
    media,
  }

  return <ThemeProvider theme={themeConfig}>{children}</ThemeProvider>
}

export default Theme
