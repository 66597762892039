import React, { useRef } from 'react'
import { useParams } from 'react-router-dom'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { Form } from 'components/collections'
import { closeReport } from '../modules/datasource'

const defaultProps = {}
const propTypes = {}

export const CloseReportForm = ({ isOpen, onClose }) => {
  const toast = useToast()
  const initialRef = useRef()
  const { reportId } = useParams()

  const submit = async values => {
    await closeReport({ reportId, values })
    toast({
      title: 'Report has been closed',
      status: 'success',
      isClosable: true,
      position: 'top',
    })
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialRef}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Close report</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Form onSubmit={submit}>
            <VStack spacing={3} align="flex-start">
              <Form.Textarea
                ref={initialRef}
                name="comments"
                label="Comment"
                maxlength={500}
                required
              />
            </VStack>

            <ModalFooter>
              <Form.Button
                type="submit"
                colorScheme="blue"
                mr={-6}
                displayLoading
              >
                Submit
              </Form.Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

CloseReportForm.defaultProps = defaultProps
CloseReportForm.propTypes = propTypes

export default CloseReportForm
