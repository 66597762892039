import * as R from 'ramda'
import React, { createContext, useContext, useState, useEffect } from 'react'
// import jwtDecode from 'jwt-decode'
import api from 'shared/api'
import tokens from 'shared/tokens'

const SessionContext = createContext()

export function SessionProvider({ children }) {
  const [user, setUser] = useState(tokens.getUser())

  const loadProfile = async () => {
    return await api
      .get('/users/profile', {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      })
      .then(function (response) {
        const { data } = response
        setUser(u => ({ ...u, profile: data }))
      })
  }

  const postTokens = async body => {
    const res = await api
      .post('/auth/email/sign-in', {
        ...body,
        skipJWT: true,
      })
      .then(({ data, status }) => {
        if (status === 200) {
          if (
            R.includes('Admin', data.roles) ||
            R.includes('SuperAdmin', data.roles)
          ) {
            setUser(data)
            tokens.setTokens(data)
            loadProfile()
            return
          } else {
            return { errors: { message: 'User is not admin' } }
          }
        }
      })
      .catch(({ response }) => {
        return { errors: response?.data }
      })

    return res
  }

  const clearSession = () => {
    tokens.removeUser()
    setUser(null)
    window.location = '/'
  }

  useEffect(() => {
    if (tokens.getLocalAccessToken()) {
      loadProfile()
    }
  }, [tokens.getLocalAccessToken()])

  return (
    <SessionContext.Provider
      value={{ user, postTokens, clearSession, loadProfile }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export function useSession() {
  return useContext(SessionContext)
}
