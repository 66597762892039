import React from 'react'
import List from './List'

const Comments = () => ({
  index: true,
  path: '/comments',
  element: <List />,
})

export default Comments
