import React from 'react'
import { useColorModeValue } from '@chakra-ui/react'
import styled from 'styled-components'
import { Table as TableComponent } from 'components/modules'

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${({ background, theme }) => theme.chakra(background)};
  transition: background 0.3s;
`

export const Container = props => (
  <StyledContainer
    background={useColorModeValue('gray.300', 'gray.700')}
    {...props}
  />
)

export const Content = styled.div`
  flex: 1;
  overflow: auto;
`

export const StyledPanel = styled.div`
  margin: 15px;
  background: ${({ background, theme }) => theme.chakra(background)};
  transition: background 0.3s;
`

export const Panel = props => (
  <StyledPanel background={useColorModeValue('white', 'gray.800')} {...props} />
)

export const Table = styled(TableComponent)`
  max-height: 70vh;
  height: 70vh;
`
