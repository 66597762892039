import React from 'react'
import { RiDashboardFill } from 'react-icons/ri'
// import { CgFeed } from 'react-icons/cg'

export const sidebarConfig = [
  {
    title: 'Dashboard',
    icon: <RiDashboardFill />,
    routes: [
      {
        title: 'Articles',
        path: '/articles',
      },
      {
        title: 'Users',
        path: '/users',
      },
      {
        title: 'Groups',
        path: '/groups',
      },
      {
        title: 'Moderation',
        type: 'title',
      },
      {
        title: 'Articles',
        path: '/article-reports',
      },
      {
        title: 'Comments',
        path: '/comments',
      },
      {
        title: 'Tags',
        path: '/tags',
      },
      {
        title: 'Questions',
        path: '/questions',
      },
      {
        title: 'Answers',
        path: '/answers',
      },
      {
        title: 'Staff pick',
        path: '/staff-pick',
      },
    ],
  },
  // {
  //   title: 'Feed',
  //   icon: <CgFeed />,
  //   routes: [
  //     {
  //       title: 'Staff pick',
  //       path: '/staff-pick',
  //     },
  //   ],
  // },
]
