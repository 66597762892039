import React, { useState, useMemo } from 'react'
import * as L from 'layouts/ListLayout'
import { VscOpenPreview } from 'react-icons/vsc'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  VStack,
  useToast,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react'
import { Form } from 'components/collections'

import { doLoad, doCreateTag } from '../modules/datasource'
import { getColumns } from '../modules/constants'

const defaultProps = {}
const propTypes = {}

const host = process.env.REACT_APP_BASE_URL

export const TagList = () => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [listState, setListState] = useState({})

  const { columnsConfig } = useMemo(() => {
    return getColumns()
  }, [])

  const toolbarConfig = useMemo(() => {
    return {
      addButton: {
        label: 'Create tag',
        onClick: onOpen,
      },
      searchProps: {
        name: 'name',
        placeholder: 'Type a tag name to search for tags',
      },
    }
  }, [])

  const load = async loadProps => {
    const tableState = await doLoad(loadProps)
    setListState(tableState)
    return tableState
  }

  const submit = async values => {
    await doCreateTag(values)
    toast({
      title: 'Tag created!',
      status: 'success',
      isClosable: true,
      position: 'top',
    })
    onClose()
  }

  return (
    <L.Container>
      <L.Content>
        <L.Panel>
          <L.Table
            fetchData={load}
            columns={columnsConfig}
            toolbarConfig={toolbarConfig}
            actions={{
              preview: {
                onClick: ({ original }) => {
                  const { slug } = original
                  window.open(`${host}/topics/${slug}/`, '_blank')
                },
                Icon: () => <VscOpenPreview />,
                disabled: ({ original }) => !original.slug,
                tooltipConfig: {
                  label: 'Preview',
                },
              },
            }}
            {...listState}
          />
        </L.Panel>
      </L.Content>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={680}>
          <ModalHeader>Create new tag</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form
              onSubmit={submit}
              initialValues={{
                name: '',
              }}
            >
              <VStack spacing={3} align="flex-start">
                <Form.Input
                  name="name"
                  label="Name"
                  placeholder="Tag name"
                  required
                />
              </VStack>
              <ModalFooter>
                <Popover placement="left">
                  <PopoverTrigger>
                    <div>
                      <Form.Button mr={-6} type="button" colorScheme="blue">
                        Save
                      </Form.Button>
                    </div>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>Confirmation!</PopoverHeader>
                    <PopoverBody>
                      Are you sure you want to create this tag?
                      <br />
                    </PopoverBody>
                    <PopoverFooter>
                      <Form.Button
                        type="submit"
                        colorScheme="red"
                        displayLoading
                      >
                        Yes, Create tag
                      </Form.Button>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
              </ModalFooter>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </L.Container>
  )
}

TagList.defaultProps = defaultProps
TagList.propTypes = propTypes

export default TagList
