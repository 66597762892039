import * as R from 'ramda'
import api from 'shared/api'
import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const doLoad = async userId => {
  return api.get(`/admin/users/${userId}`)
}

const uploadImage = async ({ imageSrc }) => {
  if (R.is(String, imageSrc)) {
    return null
  }
  const { data: postData } = await api.post('/upload/image')

  const formData = new FormData()

  formData.append('file', imageSrc)

  await instance.post(postData?.postUrl, formData)

  return postData?.id
}

export const doSave = async ({ userId, values }) => {
  const imageSrc = R.head(values?.profileImage || [])

  const profileImage = imageSrc && (await uploadImage({ imageSrc }))

  return api.put(`/admin/users/${userId}`, {
    ...values,
    profileImage,
    allowAMQ: values?.allowAMQ === 'true',
  })
}

export const doCancelSubscription = async userId => {
  return api.patch(`/admin/billing/${userId}/cancel-subscription`)
}
