import axios from 'axios'
import tokens from 'shared/tokens'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use(
  config => {
    const token = tokens.getLocalAccessToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  res => {
    return res
  },
  function (error) {
    if ([401, 403].includes(error?.response?.status)) {
      tokens.removeUser()
      window.location = '/'
    }
    return Promise.reject(error)
  }
)

export default instance
