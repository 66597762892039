import React from 'react'
import styled from 'styled-components'

import {
  UserCell,
  DateCell,
  IdCell,
  StatusCell,
} from '../components/UserList.styles'

const MailCell = styled.a`
  color: ${({ theme }) => theme.colors.blue500};
  &:hover {
    color: ${({ theme }) => theme.colors.blue200};
  }
`

export const getColumns = () => {
  const COLUMNS_CONFIG = [
    {
      Header: 'Id',
      id: 'id',
      Cell: IdCell,
      style: {
        maxWidth: 100,
        width: '100%',
      },
    },
    {
      Header: 'Name / Username',
      Cell: UserCell,
      id: 'name',
      width: 60,
    },
    {
      Header: 'Role',
      accessor: 'role',
      id: 'role',
      style: {
        maxWidth: 140,
        width: '100%',
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      id: 'email',
      Cell: ({ value }) =>
        value && <MailCell href={`mailto:${value}`}>{value}</MailCell>,
    },
    {
      Header: 'Physician',
      id: 'registeredPhysician',
      Cell: StatusCell,
      style: {
        maxWidth: 200,
        width: '100%',
      },
    },
    {
      Header: 'Last login / Expires at',
      id: 'lastLoginAt',
      Cell: DateCell,
      style: {
        maxWidth: 200,
        width: '100%',
      },
    },
  ]

  return { columnsConfig: COLUMNS_CONFIG }
}
