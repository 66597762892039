import React from 'react'
import { Select } from '@chakra-ui/react'

const defaultProps = {}
const propTypes = {}

export const SelectComponent = props => {
  const { options = [] } = props
  return (
    <Select {...props}>
      {options.map(item => {
        const { label, value } = item
        return (
          <option key={label} value={value}>
            {label}
          </option>
        )
      })}
    </Select>
  )
}

SelectComponent.defaultProps = defaultProps
SelectComponent.propTypes = propTypes

export default SelectComponent
