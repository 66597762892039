import * as R from 'ramda'
import React, { useState, useEffect } from 'react'
import { useColorModeValue, Box, Square, VStack, Image } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { sidebarConfig } from '../modules/constants'

import * as S from './Sidebar.styles'

const defaultProps = {}
const propTypes = {}

export const Sidebar = () => {
  const [selectedOption, selectOption] = useState(0)
  const openOption = sidebarConfig[selectedOption]
  const location = useLocation()
  const { pathname } = location

  useEffect(() => {
    const currentIndex = R.findIndex(({ routes }) => {
      const path = '/' + R.split('/', pathname)[1]
      return R.any(R.propEq('path', path), routes)
    }, sidebarConfig)
    selectOption(currentIndex)
  }, [pathname])

  return (
    <Box
      bg={useColorModeValue('gray.100', 'transparent')}
      as="nav"
      aria-label="Main Navigation"
      minW="60px"
      w={260}
      h="100vh"
      flexShrink={0}
      display="flex"
      borderRight="1px"
      borderColor={useColorModeValue('gray.200', 'whiteAlpha.100')}
      position="sticky"
      top={0}
    >
      <Box
        bg={useColorModeValue('blue.600', 'blue.900')}
        aria-label="Main Navigation"
        pos="sticky"
        sx={{
          overscrollBehavior: 'contain',
        }}
        w="60px"
        h="100%"
        pb="16px"
        pt="16px"
        overflow="hidden"
        className="sidebar-content"
        flexShrink={0}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Square size={50}>
          <Image src="/tsnLogo.svg" />
        </Square>
        <VStack mt="60px" spacing={3}>
          {sidebarConfig.map((route, index) => {
            return (
              <S.MainOption
                isActive={index === selectedOption}
                onClick={() => selectOption(index)}
                key={route?.title}
                {...route}
              />
            )
          })}
        </VStack>
      </Box>
      <Box w="100%">
        <S.TSNTitle>Admin</S.TSNTitle>
        <S.SubOptions key={selectedOption} {...openOption} />
      </Box>
    </Box>
  )
}

Sidebar.defaultProps = defaultProps
Sidebar.propTypes = propTypes

export default Sidebar
