import React from 'react'
import List from './List'

const Articles = () => ({
  index: true,
  path: '/groups',
  element: <List />,
})

export default Articles
