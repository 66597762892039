import * as R from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Button,
  Checkbox,
  Input,
  PopoverBody,
  PopoverFooter,
} from '@chakra-ui/react'
import { Select } from 'components/elements'

const CheckFilter = ({ label, value, ...rest }) => {
  return (
    <Checkbox w="fit-content" isChecked={value} {...rest}>
      {label}
    </Checkbox>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  /* width: 300px; */
`

const Content = styled.div`
  display: grid;
  grid-gap: 15px;
`

const Label = styled.div`
  font-weight: 500;
  margin-bottom: 5px;
  ${({ theme }) => theme.font.weight.bold};
`

const FieldContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
`

const FilterMap = {
  input: Input,
  select: Select,
  checkbox: CheckFilter,
}

const Filters = ({ filters, tableFilters, setFilters, close, style }) => {
  const [_filters, setTempFilters] = useState(tableFilters)

  const updateFilter = (name, value) => {
    const filterIndex = R.findIndex(R.propEq('name', name))(_filters)
    if (filterIndex > -1) {
      setTempFilters(R.adjust(filterIndex, x => ({ ...x, value }))(_filters))
      return
    }
    setTempFilters([..._filters, { name, value }])
  }

  const resetFilters = () => {
    const filtersToReset = R.pluck('name', filters)
    setFilters(f => {
      return R.reject(({ name }) => R.includes(name, filtersToReset), f)
    })
    close()
  }

  return (
    <Container style={style}>
      <PopoverBody>
        <Content>
          {filters.map((item, key) => {
            // eslint-disable-next-line no-unused-vars
            const { type, label, name, defaultValue, ...rest } = item
            const FilterComponent = FilterMap[type] || FilterMap.input
            const nativeLabel = type === 'checkbox'
            const { value } = R.find(R.propEq('name', name))(_filters) || {}

            return (
              <FieldContainer key={key}>
                {!nativeLabel && label && <Label>{label}</Label>}
                <FilterComponent
                  {...rest}
                  label={nativeLabel ? label : ''}
                  value={value}
                  onChange={e =>
                    updateFilter(name, e?.target?.value || e?.target?.checked)
                  }
                />
              </FieldContainer>
            )
          })}
        </Content>
      </PopoverBody>
      <PopoverFooter>
        <Button
          colorScheme="blue"
          onClick={() => {
            setFilters(_filters)
            close()
          }}
        >
          Apply
        </Button>
        <Button marginLeft={2} onClick={resetFilters}>
          Reset
        </Button>
      </PopoverFooter>
    </Container>
  )
}

export default Filters
