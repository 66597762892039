import React from 'react'
import Home from './components/Home'

const Page1Routes = () => ({
  index: true,
  path: '',
  element: <Home />,
})

export default Page1Routes
