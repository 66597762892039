import React from 'react'
import { Outlet, Navigate, useLocation } from 'react-router-dom'
import { Box, Flex } from '@chakra-ui/react'
import { useSession } from 'context/Session'
import Header from './Header'
import Sidebar from './Sidebar'
// import Sidebar from './Sidebar'

const defaultProps = {}
const propTypes = {}

export const CoreLayout = () => {
  const { user } = useSession()
  const location = useLocation()

  if (!user) {
    return <Navigate to="/sign-in" state={{ from: location }} />
  }

  return (
    <Box as="main" className="main-content" w="full" mx="auto" display="flex">
      <Sidebar />
      {/* <Sidebar
      user={user}
      isLoadingMiddleware={isLoadingMiddleware}
      signOut={signOut}
    /> */}
      <Flex flex="1" minW="0" direction="column">
        <Header />
        <Outlet />
      </Flex>
    </Box>
  )
}

CoreLayout.defaultProps = defaultProps
CoreLayout.propTypes = propTypes

export default CoreLayout
