import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'

const defaultProps = {}
const propTypes = {}

const StyledLabel = styled.div`
  ${({ theme }) => theme.font.h5};
  white-space: pre-line;
`

export const Label = ({ name, label, desc, accessor, ...rest }) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const value = field?.value
        const displayValue = accessor ? accessor(value) : value
        return (
          <FormControl
            isInvalid={form.errors[name] && form.touched[name]}
            {...rest}
          >
            {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
            <StyledLabel>{displayValue}</StyledLabel>
            <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
            {desc && <FormHelperText>{desc}</FormHelperText>}
          </FormControl>
        )
      }}
    </Field>
  )
}

Label.defaultProps = defaultProps
Label.propTypes = propTypes

export default Label
