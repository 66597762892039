import React from 'react'
import { useColorModeValue, Flex } from '@chakra-ui/react'
import styled from 'styled-components'
import { Loading } from 'components/elements'

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${({ background, theme }) => theme.chakra(background)};
  transition: background 0.3s;
`

export const Container = props => (
  <StyledContainer
    background={useColorModeValue('gray.300', 'gray.700')}
    {...props}
  />
)

export const Header = styled.div`
  padding: 15px 15px 5px;
  display: flex;
  align-items: center;
`

export const Content = styled.div`
  flex: 1;
  overflow: auto;
`

export const StyledPanel = styled.div`
  margin: 15px;
  background: ${({ background, theme }) => theme.chakra(background)};
  transition: background 0.3s;
  display: flex;
  flex-direction: column;
`
const PanelHeader = styled.div`
  font-size: 20px;
  padding: 5px 15px;
`
const PanelContent = styled.div``

export const Panel = ({ title, style, className, ...props }) => {
  return (
    <StyledPanel
      background={useColorModeValue('white', 'gray.800')}
      style={style}
      className={className}
    >
      {title && <PanelHeader>{title}</PanelHeader>}
      <PanelContent {...props} />
    </StyledPanel>
  )
}

export const LoadingPanel = () => {
  return (
    <Container>
      <Content>
        <Panel>
          <Flex
            flex="1"
            minW="0"
            minH={400}
            direction="column"
            alignItems="center"
            justifyContent="center"
            padding={50}
          >
            <Loading />
          </Flex>
        </Panel>
      </Content>
    </Container>
  )
}
