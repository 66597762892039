import * as R from 'ramda'
import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { debounce } from 'lodash'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  VStack,
  useToast,
  Input,
  InputLeftAddon,
  InputGroup,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react'
import { Form } from 'components/collections'
import { Loading } from 'components/elements'
import { ImSearch } from 'react-icons/im'
import { useFormikContext } from 'formik'

import { ArticleOption, UserOption } from './ArticlesList.styles'
import { doLoad, doLoadUsers, changeUser } from '../modules/datasource'

const defaultProps = {}
const propTypes = {}

const SelectorContainer = styled.div`
  width: 100%;
`

const ArticleSelector = React.forwardRef((props, _ref) => {
  const { setStep } = props
  const [isFetching, setIsFetching] = useState(false)
  const [list, setList] = useState([])
  const { values, setFieldValue } = useFormikContext()

  const selectedId = values?.article?.articleId

  const debounceFetch = debounce(e => {
    setIsFetching(true)
    setFieldValue('article', '')
    if (!e?.target?.value) {
      setList([])
      setIsFetching(false)
      return
    }
    doLoad({ filters: [{ name: 'title', value: e?.target?.value }] }).then(
      ({ list }) => {
        setList(list)
        setIsFetching(false)
      }
    )
  }, 800)

  return (
    <SelectorContainer {...props}>
      <InputGroup mb={30}>
        <InputLeftAddon>
          <ImSearch />
        </InputLeftAddon>
        <Input
          placeholder="Type to search article"
          onChange={debounceFetch}
          ref={_ref}
        />
      </InputGroup>
      <VStack>
        {isFetching ? (
          <Loading />
        ) : (
          list.map(item => (
            <ArticleOption
              onClick={() => setFieldValue('article', item)}
              key={item.articleId}
              selected={item.articleId === selectedId}
              {...item}
            />
          ))
        )}
      </VStack>
      <ModalFooter>
        <Form.Button
          disabled={!selectedId}
          type="button"
          colorScheme="blue"
          mr={-6}
          displayLoading
          onClick={() => setStep('user')}
        >
          Next
        </Form.Button>
      </ModalFooter>
    </SelectorContainer>
  )
})

const UserSelector = React.forwardRef((props, _ref) => {
  const [isFetching, setIsFetching] = useState(false)
  const [list, setList] = useState([])
  const { values, setFieldValue } = useFormikContext()

  const selectedId = values?.user?.id

  const debounceFetch = debounce(e => {
    setIsFetching(true)
    setFieldValue('user', '')
    if (!e?.target?.value) {
      setList([])
      setIsFetching(false)
      return
    }
    doLoadUsers({
      filters: [{ name: 'emailOrUsername', value: e?.target?.value }],
    }).then(({ list }) => {
      setList(list)
      setIsFetching(false)
    })
  }, 800)

  return (
    <SelectorContainer {...props}>
      <InputGroup mb={30}>
        <InputLeftAddon>
          <ImSearch />
        </InputLeftAddon>
        <Input
          placeholder="Search user by email or username"
          onChange={debounceFetch}
          ref={_ref}
          autoFocus
        />
      </InputGroup>
      <VStack>
        {isFetching ? (
          <Loading />
        ) : (
          list.map(item => (
            <UserOption
              onClick={() => setFieldValue('user', item)}
              key={item.id}
              selected={item.id === selectedId}
              {...item}
            />
          ))
        )}
      </VStack>
      <ModalFooter>
        <Popover placement="left">
          <PopoverTrigger>
            <div>
              <Form.Button
                disabled={!selectedId}
                mr={-6}
                type="button"
                colorScheme="blue"
              >
                Save
              </Form.Button>
            </div>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Confirmation!</PopoverHeader>
            <PopoverBody>
              Are you sure you want to update the published of this article?
              <br />
            </PopoverBody>
            <PopoverFooter>
              <Form.Button type="submit" colorScheme="red" displayLoading>
                Yes, Update user
              </Form.Button>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      </ModalFooter>
    </SelectorContainer>
  )
})

const stepMap = {
  article: ArticleSelector,
  user: UserSelector,
}

export const UpdateAuthor = ({ isOpen, onClose, setListState }) => {
  const toast = useToast()
  const initialRef = useRef()
  const [step, setStep] = useState('article')

  const submit = async values => {
    await changeUser(values)
    toast({
      title: 'Article author updated!',
      status: 'success',
      isClosable: true,
      position: 'top',
    })
    setListState(state => {
      const { list } = state
      const article = values?.article
      const articleIndex = R.findIndex(
        R.propEq('articleId', article?.articleId),
        list
      )
      if (articleIndex > -1) {
        return {
          ...state,
          list: R.update(
            articleIndex,
            {
              ...article,
              profile: values?.user,
              username: values?.user?.username,
            },
            list
          ),
        }
      }
      return state
    })
    onClose()
  }

  const FormComponent = stepMap[step]

  const config = {
    setStep,
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialRef}>
      <ModalOverlay />
      <ModalContent maxW={680}>
        <ModalHeader>Update author</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Form
            onSubmit={submit}
            initialValues={{
              articleId: '',
              userId: '',
            }}
          >
            <VStack spacing={3} align="flex-start">
              <FormComponent ref={initialRef} {...config} />
            </VStack>
          </Form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

UpdateAuthor.defaultProps = defaultProps
UpdateAuthor.propTypes = propTypes

export default UpdateAuthor
