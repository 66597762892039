import React from 'react'

const defaultProps = {}
const propTypes = {}

export const NotFound = () => {
  return <div>404</div>
}

NotFound.defaultProps = defaultProps
NotFound.propTypes = propTypes

export default NotFound
