import React from 'react'
import { useColorModeValue } from '@chakra-ui/react'
import styled from 'styled-components'
import ReactPaginate from 'react-paginate'
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from 'react-icons/md'

const defaultProps = {
  pageRangeDisplayed: 3,
}

const StyledPagination = styled(ReactPaginate)`
  user-select: none;
  display: flex;
  padding-left: 0;
  list-style: none;
  .page-item:not(:first-child) .page-link {
    margin-left: -1px;
  }
  .page-item.active .page-link {
    z-index: 3;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ activeBg, theme }) => theme.chakra(activeBg)};
    border-color: ${({ activeBg, theme }) => theme.chakra(activeBg)};
  }
  .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: ${({ disabledBg, theme }) => theme.chakra(disabledBg)};
    border: 1px solid ${({ disabledBc, theme }) => theme.chakra(disabledBc)};
  }

  .page-item:first-child .page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .page-link {
    padding: 0.375rem 0.75rem;
    position: relative;
    display: block;
    color: ${({ theme }) => theme.colors.blue400};
    text-decoration: none;
    background-color: ${({ itemBg, theme }) => theme.chakra(itemBg)};
    border: 1px solid ${({ itemBc, theme }) => theme.chakra(itemBc)};
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .page-link:hover {
    z-index: 2;
    color: ${({ itemHoverC, theme }) => theme.chakra(itemHoverC)};
    background-color: ${({ itemHoverBg, theme }) => theme.chakra(itemHoverBg)};
    border: 1px solid ${({ itemBc, theme }) => theme.chakra(itemBc)};
  }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.25rem ${({ focusBs, theme }) => theme.chakra(focusBs)};
  }
`

const propTypes = {}

const StyledPrev = styled(MdOutlineNavigateBefore)`
  font-size: 24px;
`
const StyledNext = styled(MdOutlineNavigateNext)`
  font-size: 24px;
`

export const Pagination = ({ onChange, page, ...rest }) => {
  return (
    <StyledPagination
      previousLabel={<StyledPrev />}
      nextLabel={<StyledNext />}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakLabel="..."
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination"
      activeClassName="active"
      renderOnZeroPageCount={null}
      onPageChange={onChange}
      forcePage={page}
      activeBg={useColorModeValue('blue.400', 'blue.600')}
      itemBg={useColorModeValue('white', 'gray.700')}
      itemBc={useColorModeValue('gray.300', 'whiteAlpha.100')}
      itemHoverBg={useColorModeValue('gray.50', 'gray.700')}
      itemHoverC={useColorModeValue('blue.400', 'white')}
      disabledBg={useColorModeValue('gray.200', 'whiteAlpha.50')}
      disabledBc={useColorModeValue('gray.100', 'whiteAlpha.200')}
      focusBs={useColorModeValue('blue.100', 'blue.700')}
      {...rest}
    />
  )
}

Pagination.defaultProps = defaultProps
Pagination.propTypes = propTypes

export default Pagination
