import React from 'react'
import Detail from './components/ArticleReportDetail'

const Route = () => ({
  index: true,
  path: ':reportId',
  element: <Detail />,
})

export default Route
