import { chakra } from './colors'

export const chakraColors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000000',
  white: '#FFFFFF',

  whiteAlpha: {
    50: chakra.white50,
    100: chakra.white100,
    200: chakra.white200,
    300: chakra.white300,
    400: chakra.white400,
    500: chakra.white500,
    600: chakra.white600,
    700: chakra.white700,
    800: chakra.white800,
    900: chakra.white900,
  },

  blackAlpha: {
    50: chakra.black50,
    100: chakra.black100,
    200: chakra.black200,
    300: chakra.black300,
    400: chakra.black400,
    500: chakra.black500,
    600: chakra.black600,
    700: chakra.black700,
    800: chakra.black800,
    900: chakra.black900,
  },

  gray: {
    50: chakra.gray50,
    100: chakra.gray100,
    200: chakra.gray200,
    300: chakra.gray300,
    400: chakra.gray400,
    500: chakra.gray500,
    600: chakra.gray600,
    700: chakra.gray700,
    800: chakra.gray800,
    900: chakra.gray900,
  },

  red: {
    50: chakra.red50,
    100: chakra.red100,
    200: chakra.red200,
    300: chakra.red300,
    400: chakra.red400,
    500: chakra.red500,
    600: chakra.red600,
    700: chakra.red700,
    800: chakra.red800,
    900: chakra.red900,
  },

  orange: {
    50: chakra.orange50,
    100: chakra.orange100,
    200: chakra.orange200,
    300: chakra.orange300,
    400: chakra.orange400,
    500: chakra.orange500,
    600: chakra.orange600,
    700: chakra.orange700,
    800: chakra.orange800,
    900: chakra.orange900,
  },

  yellow: {
    50: chakra.yellow50,
    100: chakra.yellow100,
    200: chakra.yellow200,
    300: chakra.yellow300,
    400: chakra.yellow400,
    500: chakra.yellow500,
    600: chakra.yellow600,
    700: chakra.yellow700,
    800: chakra.yellow800,
    900: chakra.yellow900,
  },

  green: {
    50: chakra.green50,
    100: chakra.green100,
    200: chakra.green200,
    300: chakra.green300,
    400: chakra.green400,
    500: chakra.green500,
    600: chakra.green600,
    700: chakra.green700,
    800: chakra.green800,
    900: chakra.green900,
  },

  teal: {
    50: chakra.teal50,
    100: chakra.teal100,
    200: chakra.teal200,
    300: chakra.teal300,
    400: chakra.teal400,
    500: chakra.teal500,
    600: chakra.teal600,
    700: chakra.teal700,
    800: chakra.teal800,
    900: chakra.teal900,
  },

  blue: {
    50: chakra.blue50,
    100: chakra.blue100,
    200: chakra.blue200,
    300: chakra.blue300,
    400: chakra.blue400,
    500: chakra.blue500,
    600: chakra.blue600,
    700: chakra.blue700,
    800: chakra.blue800,
    900: chakra.blue900,
  },

  cyan: {
    50: chakra.cyan50,
    100: chakra.cyan100,
    200: chakra.cyan200,
    300: chakra.cyan300,
    400: chakra.cyan400,
    500: chakra.cyan500,
    600: chakra.cyan600,
    700: chakra.cyan700,
    800: chakra.cyan800,
    900: chakra.cyan900,
  },

  purple: {
    50: chakra.purple50,
    100: chakra.purple100,
    200: chakra.purple200,
    300: chakra.purple300,
    400: chakra.purple400,
    500: chakra.purple500,
    600: chakra.purple600,
    700: chakra.purple700,
    800: chakra.purple800,
    900: chakra.purple900,
  },

  pink: {
    50: chakra.pink50,
    100: chakra.pink100,
    200: chakra.pink200,
    300: chakra.pink300,
    400: chakra.pink400,
    500: chakra.pink500,
    600: chakra.pink600,
    700: chakra.pink700,
    800: chakra.pink800,
    900: chakra.pink900,
  },
}
