const white = {
  white: '#FFFFFF',
  white50: 'rgba(255, 255, 255, 0.04)',
  white100: 'rgba(255, 255, 255, 0.06)',
  white200: 'rgba(255, 255, 255, 0.08)',
  white300: 'rgba(255, 255, 255, 0.16)',
  white400: 'rgba(255, 255, 255, 0.24)',
  white500: 'rgba(255, 255, 255, 0.36)',
  white600: 'rgba(255, 255, 255, 0.48)',
  white700: 'rgba(255, 255, 255, 0.64)',
  white800: 'rgba(255, 255, 255, 0.80)',
  white900: 'rgba(255, 255, 255, 0.92)',
}
const black = {
  black: '#000000',
  black50: 'rgba(0, 0, 0, 0.04)',
  black100: 'rgba(0, 0, 0, 0.06)',
  black200: 'rgba(0, 0, 0, 0.08)',
  black300: 'rgba(0, 0, 0, 0.16)',
  black400: 'rgba(0, 0, 0, 0.24)',
  black500: 'rgba(0, 0, 0, 0.36)',
  black600: 'rgba(0, 0, 0, 0.48)',
  black700: 'rgba(0, 0, 0, 0.64)',
  black800: 'rgba(0, 0, 0, 0.80)',
  black900: 'rgba(0, 0, 0, 0.92)',
}
const gray = {
  gray: 'gray',
  gray50: '#F7FAFC',
  gray100: '#EDF2F7',
  gray200: '#E2E8F0',
  gray300: '#CBD5E0',
  gray400: '#A0AEC0',
  gray500: '#718096',
  gray600: '#4A5568',
  gray700: '#2D3748',
  gray800: '#1A202C',
  gray900: '#171923',
}
const red = {
  red: 'red',
  red50: '#FFF5F5',
  red100: '#FED7D7',
  red200: '#FEB2B2',
  red300: '#FC8181',
  red400: '#F56565',
  red500: '#E53E3E',
  red600: '#C53030',
  red700: '#9B2C2C',
  red800: '#822727',
  red900: '#63171B',
}
const orange = {
  orange: 'orange',
  orange50: '#FFFAF0',
  orange100: '#FEEBC8',
  orange200: '#FBD38D',
  orange300: '#F6AD55',
  orange400: '#ED8936',
  orange500: '#DD6B20',
  orange600: '#C05621',
  orange700: '#9C4221',
  orange800: '#7B341E',
  orange900: '#652B19',
}
const yellow = {
  yellow: 'yellow',
  yellow50: '#FFFFF0',
  yellow100: '#FEFCBF',
  yellow200: '#FAF089',
  yellow300: '#F6E05E',
  yellow400: '#ECC94B',
  yellow500: '#D69E2E',
  yellow600: '#B7791F',
  yellow700: '#975A16',
  yellow800: '#744210',
  yellow900: '#5F370E',
}
const green = {
  green: 'green',
  green50: '#F0FFF4',
  green100: '#C6F6D5',
  green200: '#9AE6B4',
  green300: '#68D391',
  green400: '#48BB78',
  green500: '#38A169',
  green600: '#2F855A',
  green700: '#276749',
  green800: '#22543D',
  green900: '#1C4532',
}
const teal = {
  teal: 'teal',
  teal50: '#E6FFFA',
  teal100: '#B2F5EA',
  teal200: '#81E6D9',
  teal300: '#4FD1C5',
  teal400: '#38B2AC',
  teal500: '#319795',
  teal600: '#2C7A7B',
  teal700: '#285E61',
  teal800: '#234E52',
  teal900: '#1D4044',
}
const blue = {
  blue: 'blue',
  blue50: '#ebf8ff',
  blue100: '#bee3f8',
  blue200: '#90cdf4',
  blue300: '#63b3ed',
  blue400: '#4299e1',
  blue500: '#3182ce',
  blue600: '#2b6cb0',
  blue700: '#2c5282',
  blue800: '#2a4365',
  blue900: '#1A365D',
}
const cyan = {
  cyan: 'cyan',
  cyan50: '#EDFDFD',
  cyan100: '#C4F1F9',
  cyan200: '#9DECF9',
  cyan300: '#76E4F7',
  cyan400: '#0BC5EA',
  cyan500: '#00B5D8',
  cyan600: '#00A3C4',
  cyan700: '#0987A0',
  cyan800: '#086F83',
  cyan900: '#065666',
}
const purple = {
  purple: 'purple',
  purple50: '#FAF5FF',
  purple100: '#E9D8FD',
  purple200: '#D6BCFA',
  purple300: '#B794F4',
  purple400: '#9F7AEA',
  purple500: '#805AD5',
  purple600: '#6B46C1',
  purple700: '#553C9A',
  purple800: '#44337A',
  purple900: '#322659',
}
const pink = {
  pink: 'pink',
  pink50: '#FFF5F7',
  pink100: '#FED7E2',
  pink200: '#FBB6CE',
  pink300: '#F687B3',
  pink400: '#ED64A6',
  pink500: '#D53F8C',
  pink600: '#B83280',
  pink700: '#97266D',
  pink800: '#702459',
  pink900: '#521B41',
}

const chakra = {
  transparent: 'transparent',
  current: 'currentColor',
  ...white,
  ...black,
  ...gray,
  ...red,
  ...orange,
  ...yellow,
  ...green,
  ...teal,
  ...blue,
  ...cyan,
  ...purple,
  ...pink,
}

export { chakra }
