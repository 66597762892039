import React, { useState, useMemo } from 'react'
import * as L from 'layouts/ListLayout'
import { VscOpenPreview } from 'react-icons/vsc'
import { useNavigate } from 'react-router-dom'
import { doLoad } from '../modules/datasource'
import { getColumns } from '../modules/constants'

const defaultProps = {}
const propTypes = {}

const host = process.env.REACT_APP_BASE_URL

export const StaffPickList = () => {
  const [listState, setListState] = useState({})
  const navigate = useNavigate()

  const { columnsConfig } = useMemo(() => {
    return getColumns()
  }, [])

  const toolbarConfig = {
    addButton: {
      onClick: () => navigate('/staff-pick/edit'),
      label: 'Edit',
    },
  }

  const load = async loadProps => {
    const tableState = await doLoad(loadProps)
    setListState(tableState)
    return tableState
  }

  return (
    <L.Container>
      <L.Content>
        <L.Panel>
          <L.Table
            fetchData={load}
            columns={columnsConfig}
            toolbarConfig={toolbarConfig}
            actions={{
              preview: {
                onClick: ({ original }) => {
                  const { slug, username } = original
                  window.open(`${host}/p/${username}/${slug}`, '_blank')
                },
                Icon: () => <VscOpenPreview />,
                disabled: ({ original }) =>
                  !original.slug || !original?.profile?.username,
              },
            }}
            {...listState}
          />
        </L.Panel>
      </L.Content>
    </L.Container>
  )
}

StaffPickList.defaultProps = defaultProps
StaffPickList.propTypes = propTypes

export default StaffPickList
