// import * as R from 'ramda'
import api, { getPaginatedList } from 'shared/api'

export const doLoad = async props => {
  return await getPaginatedList('/admin/groups', props)
}

export const deleteGroup = async ({ id, values }) => {
  return api.delete(`/admin/groups/${id}`, { data: values })
}
