import * as R from 'ramda'
import React, { useState, useMemo, useRef } from 'react'
import styled from 'styled-components'
import * as L from 'layouts/ListLayout'
import { VscOpenPreview } from 'react-icons/vsc'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useToast,
} from '@chakra-ui/react'
import { doLoad, doApprove, doDelete } from '../modules/datasource'
import { getColumns } from '../modules/constants'

const defaultProps = {}
const propTypes = {}

const host = process.env.REACT_APP_BASE_URL

const CommentPreview = styled.div`
  max-height: 200px;
  overflow-y: auto;
`

export const CommentsList = () => {
  const [isFetching, setIsFetching] = useState(false)
  const [approveOpen, setApproveOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [delComment, setDeleteComment] = useState(null)
  const toast = useToast()
  const onClose = () => {
    setApproveOpen(false)
    setDeleteOpen(false)
    setDeleteComment(null)
  }
  const [listState, setListState] = useState({})
  const cancelRef = useRef()

  const { columnsConfig } = useMemo(() => {
    return getColumns()
  }, [])

  const toolbarConfig = useMemo(() => {
    return {
      filters: [
        {
          name: 'showReviewed',
          type: 'checkbox',
          label: 'Show reviewed',
        },
      ],
      addButton: {
        onClick: () => {
          setIsFetching(false)
          setApproveOpen(true)
        },
        label: 'Approve',
        disabled: R.isEmpty(selectedRows),
      },
    }
  }, [selectedRows])

  const load = async loadProps => {
    const tableState = await doLoad(loadProps)
    setListState(tableState)
    return tableState
  }

  const approveComments = async () => {
    setIsFetching(true)
    try {
      await doApprove(selectedRows)
      onClose()
      setListState(state => {
        const { list } = state
        const keys = R.pluck('id', selectedRows)
        return {
          ...state,
          list: R.map(i => {
            if (R.includes(i?.id, keys)) {
              return {
                ...i,
                reviewed: true,
              }
            }
            return i
          }, list),
        }
      })
      setSelectedRows([])
    } catch (e) {
      setIsFetching(false)
      toast({
        title: e?.response?.data?.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      })
    }
  }

  const deleteComment = async () => {
    setIsFetching(true)
    await doDelete(delComment)
    onClose()
    setListState(state => {
      const { list } = state
      return {
        ...state,
        list: R.reject(R.propEq('id', delComment?.id), list),
      }
    })
  }

  return (
    <L.Container>
      <L.Content>
        <L.Panel>
          <L.Table
            fetchData={load}
            columns={columnsConfig}
            toolbarConfig={toolbarConfig}
            actions={{
              preview: {
                onClick: ({ original }) => {
                  const { link } = original
                  window.open(link, '_blank')
                },
                tooltipConfig: {
                  label: 'Preview',
                },
                Icon: () => <VscOpenPreview />,
                disabled: ({ original }) => !original.link,
              },
              deleteClick: ({ original }) => {
                setIsFetching(false)
                setDeleteComment(original)
                setDeleteOpen(true)
              },
            }}
            select={setSelectedRows}
            selectedRows={selectedRows}
            {...listState}
            // rowConfig={{
            //   alignItems: 'baseline',
            // }}
          />
        </L.Panel>
      </L.Content>
      <AlertDialog
        isOpen={approveOpen || deleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent maxWidth={670}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {approveOpen ? 'Approve comments' : 'Delete comment'}
            </AlertDialogHeader>

            <AlertDialogBody>
              {approveOpen
                ? `Are you sure? You want to approve ${selectedRows?.length} comment(s)`
                : `Are you sure? You want to delete ${delComment?.user}'s comment?`}
              {deleteOpen && (
                <>
                  <br />
                  <br />
                  <strong>Comment:</strong>
                  <br />
                  <CommentPreview>{delComment?.text}</CommentPreview>
                </>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button disabled={isFetching} ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme={approveOpen ? 'green' : 'red'}
                onClick={approveOpen ? approveComments : deleteComment}
                ml={3}
                isLoading={isFetching}
              >
                {approveOpen ? 'Approve' : 'Delete'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </L.Container>
  )
}

CommentsList.defaultProps = defaultProps
CommentsList.propTypes = propTypes

export default CommentsList
