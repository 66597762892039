export const getColumns = () => {
  const COLUMNS_CONFIG = [
    {
      Header: 'Id',
      id: 'id',
      accessor: 'id',
      style: {
        maxWidth: 100,
        width: '100%',
      },
    },
    {
      Header: 'Name',
      id: 'name',
      accessor: 'name',
    },
    {
      Header: 'Slug',
      id: 'slug',
      accessor: 'slug',
    },
  ]

  return { columnsConfig: COLUMNS_CONFIG }
}
