import * as R from 'ramda'
import React, { useRef, useState } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  useToast,
} from '@chakra-ui/react'
import { Form } from 'components/collections'
import styled from 'styled-components'

import { deleteArticle } from '../modules/datasource'

const ArticleDesc = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.gray500};
`

const defaultProps = {}
const propTypes = {}

export const RemoveArticle = ({
  isOpen,
  articleToDelete: data,
  onClose,
  setListState,
}) => {
  const [isFetching, setIsFetching] = useState(false)
  const toast = useToast()
  const initialRef = useRef()

  const submit = async values => {
    setIsFetching(true)
    deleteArticle({ ...data, values })
      .then(() => {
        toast({
          title: 'Article removed',
          status: 'success',
          isClosable: true,
          position: 'top',
        })

        setListState(state => {
          const { list } = state

          return {
            ...state,
            total: state.total - 1,
            list: R.reject(R.propEq('articleId', data?.articleId), list),
          }
        })
        onClose()
      })
      .catch(() => {
        setIsFetching(false)
        toast({
          title: 'Error removing article',
          status: 'error',
          isClosable: true,
          position: 'top',
        })
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialRef}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Delete article
          <ArticleDesc>
            Article title: <br />
            {data?.title}
          </ArticleDesc>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={submit}>
            <VStack spacing={3} align="flex-start">
              <Form.Textarea
                ref={initialRef}
                name="comments"
                label="Comment"
                maxlength={500}
                desc="You are about to delete an article."
                required
              />
            </VStack>

            <ModalFooter>
              <Popover placement="left">
                <PopoverTrigger>
                  <div>
                    <Form.Button mr={-6} type="button" colorScheme="red">
                      Delete
                    </Form.Button>
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>Confirmation!</PopoverHeader>
                  <PopoverBody>
                    Are you sure you want to delete this article?
                    <br />
                  </PopoverBody>
                  <PopoverFooter>
                    <Form.Button
                      type="submit"
                      colorScheme="red"
                      displayLoading
                      isLoading={isFetching}
                    >
                      Yes, Delete
                    </Form.Button>
                  </PopoverFooter>
                </PopoverContent>
              </Popover>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

RemoveArticle.defaultProps = defaultProps
RemoveArticle.propTypes = propTypes

export default RemoveArticle
