import React from 'react'
import { CircularProgress, useColorModeValue } from '@chakra-ui/react'

const defaultProps = {}
const propTypes = {}

export const Loading = props => {
  return (
    <CircularProgress
      isIndeterminate
      color="blue.600"
      trackColor={useColorModeValue('gray.300', 'gray.700')}
      {...props}
    />
  )
}

Loading.defaultProps = defaultProps
Loading.propTypes = propTypes

export default Loading
