import React from 'react'
import { ChakraProvider, theme } from '@chakra-ui/react'
import { BrowserRouter as Router } from 'react-router-dom'
import { MenuBarProvider } from 'context/MenuBar'
import { SessionProvider } from 'context/Session'
import { Theme } from 'layouts/Theme'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import 'react-datepicker/dist/react-datepicker.css'

import Routes from './routes'

function App() {
  return (
    <Theme>
      <ChakraProvider theme={theme}>
        <DndProvider backend={HTML5Backend}>
          <MenuBarProvider>
            <SessionProvider>
              <Router>
                <Routes />
              </Router>
            </SessionProvider>
          </MenuBarProvider>
        </DndProvider>
      </ChakraProvider>
    </Theme>
  )
}

export default App
