import React, { useRef } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  useToast,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Form } from 'components/collections'
import { deleteAdmin } from '../modules/datasource'

const defaultProps = {}
const propTypes = {}

export const CloseReportForm = ({ isOpen, data, onClose }) => {
  const navigate = useNavigate()
  const toast = useToast()
  const initialRef = useRef()

  const submit = async values => {
    await deleteAdmin({ ...data, values }).then(() => {
      toast({
        title: 'Article removed',
        status: 'success',
        isClosable: true,
        position: 'top',
      })
      navigate('/article-reports')
      onClose()
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialRef}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete article</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Form onSubmit={submit}>
            <VStack spacing={3} align="flex-start">
              <Form.Textarea
                ref={initialRef}
                name="comments"
                label="Comment"
                maxlength={500}
                desc="You are about to delete an article."
                required
              />
            </VStack>

            <ModalFooter>
              <Popover placement="left">
                <PopoverTrigger>
                  <div>
                    <Form.Button mr={-6} type="button" colorScheme="red">
                      Delete
                    </Form.Button>
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>Confirmation!</PopoverHeader>
                  <PopoverBody>
                    Are you sure you want to delete this article?
                    <br />
                  </PopoverBody>
                  <PopoverFooter>
                    <Form.Button type="submit" colorScheme="red" displayLoading>
                      Yes, Delete
                    </Form.Button>
                  </PopoverFooter>
                </PopoverContent>
              </Popover>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

CloseReportForm.defaultProps = defaultProps
CloseReportForm.propTypes = propTypes

export default CloseReportForm
