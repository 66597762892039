import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { getAlphaRgb } from 'layouts/Theme/Utils'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  useToast,
} from '@chakra-ui/react'

import { doCancelSubscription } from '../modules/datasource'

export const Grid = styled.div`
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`

export const HeaderGrid = styled(Grid)`
  grid-template-columns: 234px 1fr 1fr;
`

export const ImgPreview = styled.div`
  grid-row: 1 / 3;
  position: relative;
  overflow: hidden;
  height: 234px;
  width: 100%;
  max-width: 234px;
  background: ${({ theme }) => theme.colors.gray300};
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  margin-bottom: 8px;
  ${({ src }) =>
    src &&
    css`
      background-image: ${`url(${src})`};
      background-size: cover;
      background-position: center;
      &:hover {
        &::after {
          content: 'Edit';
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: ${({ theme }) => getAlphaRgb(theme.colors.grey, 0.5)};
          color: ${({ theme }) => theme.colors.white};
        }
      }
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      &:hover {
        &::after {
          content: unset;
        }
      }
    `}
`

export const CancelSubscription = ({ isOpen, onClose, data }) => {
  const [isFetching, setIsFetching] = useState(false)
  const toast = useToast()

  const submit = async () => {
    setIsFetching(true)
    try {
      await doCancelSubscription(data?.id)
      toast({
        title: 'Subscription cancelled',
        status: 'success',
        isClosable: true,
        position: 'top',
      })
    } catch (error) {
      toast({
        title: error.response?.data?.message || 'Error cancelling subscription',
        status: 'error',
        isClosable: true,
        position: 'top',
      })
    }
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Cancel Subscription
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <ModalFooter>
            <Popover placement="left">
              <PopoverTrigger>
                <div>
                  <Button mr={-6} type="button" colorScheme="red">
                    Cancel
                  </Button>
                </div>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Confirmation!</PopoverHeader>
                <PopoverBody>
                  Are you sure you want to cancel this user subscription?
                  <br />
                </PopoverBody>
                <PopoverFooter>
                  <Button
                    type="submit"
                    colorScheme="red"
                    displayLoading
                    isLoading={isFetching}
                    onClick={submit}
                  >
                    Yes, Cancel
                  </Button>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
