import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  cursor: pointer;
  transition: all 0.3s;
  width: 12px;
  height: 8px;

  path {
    fill: ${({ theme }) => theme.colors.blue500};
  }
`

export default function ExpandIcon(props) {
  return (
    <StyledSVG width="10" height="7" viewBox="0 0 10 7" fill="none" {...props}>
      <path
        d="M1.175 0.158325L5 3.97499L8.825 0.158325L10 1.33333L5 6.33333L0 1.33333L1.175 0.158325Z"
        fill="#3182ce"
      />
    </StyledSVG>
  )
}
