import React from 'react'
import { Flex, Box, VStack } from '@chakra-ui/react'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import { Form } from 'components/collections'
import { useSession } from 'context/Session'

const defaultProps = {}
const propTypes = {}

export const SignIn = () => {
  const { user, postTokens } = useSession()
  const navigate = useNavigate()
  const location = useLocation()

  const from = location.state?.from?.pathname || '/'

  const submit = async (values, { setErrors }) => {
    const response = await postTokens(values)
    if (response?.errors) {
      setErrors({ password: response?.errors?.message })
    } else {
      navigate(from, { replace: true })
    }
  }

  if (user) {
    return <Navigate to="/" />
  }

  return (
    <Flex align="center" justify="center" height="100vh" bg="blue.100">
      <Box w="100%" maxW={400} bg="white" color="black" p={10} borderRadius={8}>
        <Form onSubmit={submit}>
          <VStack spacing={3} align="flex-start">
            <Form.Input
              name="email"
              desc="Your email here"
              label="E-mail"
              type="email"
              required
            />
            <Form.Input
              name="password"
              label="Password"
              type="password"
              required
            />
            <Form.Button displayLoading>Login</Form.Button>
          </VStack>
        </Form>
      </Box>
    </Flex>
  )
}

SignIn.defaultProps = defaultProps
SignIn.propTypes = propTypes

export default SignIn
