import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  cursor: pointer;
  transition: all 0.3s;

  g {
    [fill] {
      fill: ${({ theme }) => theme.colors.blue400};
    }
  }
`

export default function CloseIcon(props) {
  return (
    <StyledSVG
      width="24"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        d="M18.6667 7.175L17.4917 6L12.8333 10.6583L8.175 6L7 7.175L11.6583 11.8333L7 16.4917L8.175 17.6667L12.8333 13.0083L17.4917 17.6667L18.6667 16.4917L14.0083 11.8333L18.6667 7.175Z"
        fill="#462546"
      />
    </StyledSVG>
  )
}
