import { CustomCell } from '../components/GroupsList.styles'

export const getColumns = () => {
  const COLUMNS_CONFIG = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: CustomCell,
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
  ]

  return { columnsConfig: COLUMNS_CONFIG }
}
