// import { format } from 'date-fns'
import { CustomCell } from '../components/StaffPickDetail.styles'

export const getColumns = () => {
  const COLUMNS_CONFIG = [
    {
      Header: 'Author',
      accessor: 'articleId',
      Cell: CustomCell,
      width: 100,
    },
    {
      Header: 'Title',
      accessor: 'title',
      width: 100,
    },
    // {
    //   Header: 'Published At',
    //   accessor: ({ publishedAt }) =>
    //     publishedAt && format(new Date(publishedAt), 'MM/dd/yyyy'),
    //   id: 'publishedAt',
    //   width: 60,
    // },
  ]

  return { columnsConfig: COLUMNS_CONFIG }
}
