import React, { memo } from 'react'
import styled from 'styled-components'
import { useDrag, useDrop } from 'react-dnd'
import { GrClose } from 'react-icons/gr'
import { IconButton, useColorModeValue } from '@chakra-ui/react'

const Container = styled.div`
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  background-color: ${({ theme, backgroundColor }) =>
    theme.chakra(backgroundColor)};
  border-radius: 8px;
  cursor: move;
  display: flex;
  align-items: center;
`

const CloseIcon = styled(GrClose)`
  path {
    stroke: ${({ theme, color }) => theme.chakra(color)};
  }
`

const Desc = styled.div`
  flex: 1;
  ${({ theme }) => theme.utils.ellipsis};
  margin-right: 10px;
`
export const Card = memo(function Card({
  id,
  text,
  moveCard,
  findCard,
  removeCard,
}) {
  const originalIndex = findCard(id).index
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'card',
      item: { id, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item
        const didDrop = monitor.didDrop()
        if (!didDrop) {
          moveCard(droppedId, originalIndex)
        }
      },
    }),
    [id, originalIndex, moveCard]
  )
  const [, drop] = useDrop(
    () => ({
      accept: 'card',
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id)
          moveCard(draggedId, overIndex)
        }
      },
    }),
    [findCard, moveCard]
  )
  const opacity = isDragging ? 0 : 1
  return (
    <Container
      backgroundColor={useColorModeValue('gray.100', 'whiteAlpha.200')}
      ref={node => drag(drop(node))}
      style={{ opacity }}
    >
      <Desc>{text}</Desc>
      <IconButton key={`removePick-${id}`} onClick={() => removeCard(id)}>
        <CloseIcon color={useColorModeValue('black', 'white')} />
      </IconButton>
    </Container>
  )
})
