import jwtDecode from 'jwt-decode'

const KEY = 'tsnAdminTokens'

const getLocalRefreshToken = () => {
  const tokens = JSON.parse(localStorage.getItem(KEY))
  return tokens?.refreshToken
}

const getLocalAccessToken = () => {
  const tokens = JSON.parse(localStorage.getItem(KEY))
  return tokens?.accessToken
}

const getUser = () => {
  const tokens = JSON.parse(localStorage.getItem(KEY))
  return tokens && jwtDecode(tokens.accessToken)
}

const setTokens = data => {
  localStorage.setItem(KEY, JSON.stringify(data))
}

const removeUser = () => {
  localStorage.removeItem(KEY)
}

const TokenService = {
  KEY,
  getLocalRefreshToken,
  getLocalAccessToken,
  getUser,
  setTokens,
  removeUser,
}

export default TokenService
