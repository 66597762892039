import api, { getPaginatedList } from 'shared/api'

export const doLoad = async props => {
  return await getPaginatedList('/admin/questions', {
    ...props,
  })
}

export const doDelete = async ({ id }) => {
  await api.delete(`/admin/questions/${id}`)
}
