import { DateCell } from '../components/QuestionsList.styles'
import { CustomCell } from 'routes/Articles/List/components/ArticlesList.styles'

export const getColumns = () => {
  const COLUMNS_CONFIG = [
    {
      Header: 'Author',
      accessor: 'articleId',
      Cell: CustomCell,
      style: {
        maxWidth: 270,
        width: '100%',
      },
    },
    {
      Header: 'Question',
      accessor: 'title',
      disableEllipsis: true,
    },
    {
      Header: 'Created / Updated',
      id: 'createdAt',
      Cell: DateCell,
      style: {
        maxWidth: 200,
        width: '100%',
      },
    },
  ]

  return { columnsConfig: COLUMNS_CONFIG }
}
