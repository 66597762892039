import React from 'react'
import styled from 'styled-components'

import { format } from 'date-fns'
import { Flex } from '@chakra-ui/react'

const TimeCell = styled.div`
  font-size: 14px;
  text-align: center;
`

export const DateCell = props => {
  const { row: { original: { createdAt, updatedAt } = {} } = {} } = props

  return (
    <Flex direction="column">
      <TimeCell>
        {createdAt ? format(new Date(createdAt), 'MM/dd/yyyy hh:mm aa') : '-'}
      </TimeCell>
      <TimeCell>
        {updatedAt ? format(new Date(updatedAt), 'MM/dd/yyyy hh:mm aa') : '-'}
      </TimeCell>
    </Flex>
  )
}
