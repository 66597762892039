import React from 'react'
import SignIn from './SignIn'
import AuthLayout from 'layouts/AuthLayout'

const AuthRoutes = () => ({
  element: <AuthLayout />,
  children: [{ path: 'sign-in', element: <SignIn /> }],
})

export default AuthRoutes
