import React from 'react'
import Detail from './components/StaffPickDetail'

const Route = () => ({
  index: true,
  path: 'edit',
  element: <Detail />,
})

export default Route
