import api, { getPaginatedList } from 'shared/api'

export const doLoad = async props => {
  const { filters = [] } = props
  return await getPaginatedList('/admin/articles', {
    ...props,
    filters: [...filters, { name: 'status', value: 'Approved' }],
  })
}

export const doLoadStaffPick = async () => {
  return await getPaginatedList('/public/articles/feed', {
    page: 0,
    pageSize: 99,
    filters: [{ name: 'type', value: 'StaffPick' }],
  })
}

export const doSave = async ({ list }) => {
  await api.put('/admin/articles/staff-pick', {
    articleIds: list,
  })
}
