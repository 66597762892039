import React from 'react'
import styled from 'styled-components'
import Linkify from 'linkify-react'

import { format } from 'date-fns'
import { Flex } from '@chakra-ui/react'

const TimeCell = styled.div`
  font-size: 14px;
  text-align: center;
`

export const DateCell = props => {
  const { row: { original: { createdAt, updatedAt } = {} } = {} } = props

  return (
    <Flex direction="column">
      <TimeCell>
        {createdAt ? format(new Date(createdAt), 'MM/dd/yyyy hh:mm aa') : '-'}
      </TimeCell>
      <TimeCell>
        {updatedAt ? format(new Date(updatedAt), 'MM/dd/yyyy hh:mm aa') : '-'}
      </TimeCell>
    </Flex>
  )
}

const Description = styled.div`
  ${({ theme }) => theme.font.body};
  margin-bottom: 24px;
  white-space: pre-wrap;
  padding: 30px;
  a {
    color: ${({ theme }) => theme.colors.blue500};
  }
`

export const DescriptionSubRender = props => {
  const { row: { original: { text, questionTitle } = {} } = {} } = props

  return (
    <Description>
      <div>
        <strong>Question:</strong>
        <br />
        {questionTitle}
      </div>
      <br />
      <strong>Answer:</strong>

      <br />
      <Linkify options={{ target: '_blank' }}>{text}</Linkify>
    </Description>
  )
}
