import React from 'react'
import List from './List'

const Answers = () => ({
  index: true,
  path: '/answers',
  element: <List />,
})

export default Answers
