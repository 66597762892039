import React, { useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { useDisclosure } from '@chakra-ui/react'
import { Form } from 'components/collections'
import { Breadcrumbs } from 'components/elements'
import { useMenuBar } from 'context/MenuBar'
import { doLoad } from '../modules/datasource'

import RemoveArticle from './RemoveArticleForm'
import CloseReportForm from './CloseReportForm'

import * as D from 'layouts/DetailLayout'
import * as S from './ArticleReportDetail.styles'

const defaultProps = {}
const propTypes = {}

const modalMap = {
  article: RemoveArticle,
  report: CloseReportForm,
}

const host = process.env.REACT_APP_BASE_URL

export const ArticleReportDetail = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { reportId } = useParams()
  const [modal, setModal] = useState(null)
  const [isFetching, setIsFetching] = useState(true)
  const [state, setState] = useState({})
  const { setActions } = useMenuBar()

  const openModal = m => {
    setModal(m)
    onOpen()
  }

  useEffect(() => {
    doLoad(reportId).then(({ data }) => {
      setState(data)
      setIsFetching(false)
      setActions([
        {
          label: 'Preview article',
          onClick: () => {
            const { articleSlug, username } = data
            window.open(`${host}/p/${username}/${articleSlug}`, '_blank')
          },
          visible: data?.username && data?.articleSlug,
        },
        { label: 'Delete article', onClick: () => openModal('article') },
        { label: 'Close report', onClick: () => openModal('report') },
      ])
    })
    return () => setActions([])
  }, [reportId])

  const options = useMemo(
    () => [
      { label: 'Report list', href: '/article-reports' },
      { label: state?.articleTitle },
    ],
    [state?.articleTitle]
  )

  if (isFetching) {
    return <D.LoadingPanel />
  }

  const closeModal = () => {
    onClose()
    setModal(null)
  }

  const RemoveForm = modalMap[modal]

  const modalConfig = {
    data: state,
    onClose: closeModal,
    isOpen,
  }

  return (
    <D.Container>
      <D.Header>
        <Breadcrumbs items={options} />
      </D.Header>
      <D.Content>
        <D.Panel title="Report info">
          <Form initialValues={state}>
            <S.Grid>
              <Form.Label label="Article title" name="articleTitle" />
              <Form.Label
                label="Created At"
                name="createdAt"
                accessor={v => v && format(new Date(v), 'MM/dd/yyyy')}
              />
              <Form.Label
                label="Comment"
                name="comments"
                style={{ gridColumn: '1 / 3' }}
              />
            </S.Grid>
          </Form>
        </D.Panel>
      </D.Content>
      {isOpen && <RemoveForm {...modalConfig} />}
    </D.Container>
  )
}

ArticleReportDetail.defaultProps = defaultProps
ArticleReportDetail.propTypes = propTypes

export default ArticleReportDetail
