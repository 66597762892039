import * as R from 'ramda'
import React, { useEffect, useState, useMemo } from 'react'
import { format, add, isBefore } from 'date-fns'
import { useParams, useNavigate } from 'react-router-dom'
import { useSession } from 'context/Session'
import { useMenuBar } from 'context/MenuBar'
import { Form } from 'components/collections'
import { Breadcrumbs } from 'components/elements'
import { useDisclosure } from '@chakra-ui/react'
import { doLoad, doSave } from '../modules/datasource'

import * as D from 'layouts/DetailLayout'
import * as S from './UserDetail.styles'

const defaultProps = {}
const propTypes = {}

export const UserDetail = () => {
  const navigate = useNavigate()
  const { setActions } = useMenuBar()
  const { user } = useSession()
  const { userId } = useParams()
  const [isFetching, setIsFetching] = useState(true)
  const [state, setState] = useState({})
  const isSuperAdmin = R.includes('SuperAdmin', user.roles)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const options = useMemo(
    () => [
      { label: 'User list', href: '/users' },
      { label: state?.username || state?.email },
    ],
    [state?.username]
  )

  const untilOptions = state?.memberUntil
    ? [
        {
          label: format(new Date(state?.memberUntil), 'MM/dd/yyyy'),
          value: state?.memberUntil,
        },
        {
          label: `Add + 6 months (${format(
            add(new Date(state?.memberUntil), { months: 6 }),
            'MM/dd/yyyy'
          )})`,
          value: add(new Date(state?.memberUntil), { months: 6 }),
        },
        {
          label: `Add + 12 months (${format(
            add(new Date(state?.memberUntil), { months: 12 }),
            'MM/dd/yyyy'
          )})`,
          value: add(new Date(state?.memberUntil), { months: 12 }),
        },
      ]
    : [
        {
          label: `Add + 6 months (${format(
            add(new Date(), { months: 6 }),
            'MM/dd/yyyy'
          )})`,
          value: add(new Date(), { months: 6 }),
        },
        {
          label: `Add + 12 months (${format(
            add(new Date(), { months: 12 }),
            'MM/dd/yyyy'
          )})`,
          value: add(new Date(), { months: 12 }),
        },
      ]

  useEffect(() => {
    doLoad(userId).then(({ data }) => {
      setState(data)
      setIsFetching(false)
      setActions([
        {
          label: 'Cancel Subscription',
          onClick: () => {
            onOpen()
          },
          visible: isBefore(new Date(), new Date(data.memberUntil)),
        },
      ])
    })
    return () => setActions([])
  }, [userId])

  const submit = async values => {
    await doSave({ userId, values })
    navigate('/users')
  }

  if (isFetching) {
    return <D.LoadingPanel />
  }

  const modalConfig = {
    isOpen,
    onClose,
    data: state,
  }

  return (
    <D.Container>
      <D.Header>
        <Breadcrumbs items={options} />
      </D.Header>
      <D.Content>
        <D.Panel title="User info">
          <Form
            initialValues={{
              ...state,
            }}
            onSubmit={submit}
          >
            <S.HeaderGrid>
              {isSuperAdmin ? (
                <Form.Upload
                  style={{ width: '100%', gridRow: '1 / 5' }}
                  name="profileImage"
                  renderInput={(f = []) => {
                    const previewUrl = R.head(f)

                    return (
                      <S.ImgPreview
                        src={
                          previewUrl?.preview ||
                          (!R.isEmpty(f) && f) ||
                          undefined
                        }
                      >
                        {previewUrl ? '' : 'Add a preview image'}
                      </S.ImgPreview>
                    )
                  }}
                />
              ) : (
                <S.ImgPreview src={state?.profileImage} disabled />
              )}
              <Form.Select
                placeholder="Not member"
                name="memberUntil"
                disabled={!isSuperAdmin}
                label="Add free time"
                options={untilOptions}
              />
              <Form.Select
                placeholder="Select a role"
                name="role"
                disabled={!isSuperAdmin}
                label="Role"
                options={[
                  { label: 'User', value: 'User' },
                  { label: 'Staff', value: 'Staff' },
                  { label: 'Admin', value: 'Admin' },
                  { label: 'SuperAdmin', value: 'SuperAdmin' },
                  { label: 'Journalist', value: 'Journalist' },
                ]}
                required
              />
              <Form.Input
                name="firstName"
                disabled={!isSuperAdmin}
                label="First name"
                placeholder="First name"
                style={{ gridColumn: '2 / 3' }}
                required
              />
              <Form.Input
                name="lastName"
                disabled={!isSuperAdmin}
                label="Last name"
                placeholder="Last name"
                required
              />
              <Form.Input
                name="email"
                disabled
                label="E-mail"
                type="email"
                // required
              />
              <Form.Input
                name="company"
                disabled={!isSuperAdmin}
                label="Company"
                placeholder="Company"
                required
              />
              <Form.Input
                name="position"
                disabled={!isSuperAdmin}
                label="Position"
                placeholder="Position"
                required
              />
              <Form.Select
                placeholder="Select permission"
                name="allowAMQ"
                disabled={!isSuperAdmin}
                label="Allow AMQ"
                options={[
                  { label: 'No', value: 'false' },
                  { label: 'Yes', value: 'true' },
                ]}
              />
            </S.HeaderGrid>
            <S.Grid>
              <Form.Input
                name="singleLineStatement"
                disabled={!isSuperAdmin}
                label="Single line statement"
                placeholder="Single line statement"
                maxlength={150}
                style={{ gridColumn: '1 / 3' }}
              />
              <Form.Textarea
                name="bio"
                disabled={!isSuperAdmin}
                label="Bio"
                maxlength={600}
                style={{ gridColumn: '1 / 3' }}
                rows="5"
                required
              />
              <Form.Button disabled={!isSuperAdmin} displayLoading>
                Save
              </Form.Button>
            </S.Grid>
          </Form>
        </D.Panel>
      </D.Content>
      {isOpen && <S.CancelSubscription {...modalConfig} />}
    </D.Container>
  )
}

UserDetail.defaultProps = defaultProps
UserDetail.propTypes = propTypes

export default UserDetail
