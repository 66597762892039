import { format } from 'date-fns'

export const getColumns = () => {
  const COLUMNS_CONFIG = [
    {
      Header: 'Id',
      accessor: 'id',
      maxWidth: 10,
    },
    {
      Header: 'Title',
      accessor: 'articleTitle',
    },
    {
      Header: 'Created At',
      accessor: ({ createdAt }) =>
        createdAt && format(new Date(createdAt), 'MM/dd/yyyy'),
      id: 'createdAt',
      width: 60,
    },
  ]

  return { columnsConfig: COLUMNS_CONFIG }
}
