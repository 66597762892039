import React from 'react'
import { Field } from 'formik'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Textarea,
} from '@chakra-ui/react'

const defaultProps = {}
const propTypes = {}

export const FormTexarea = React.forwardRef(
  ({ name, label, desc, validate, required, style, ...rest }, ref) => {
    const inputValidation = value => {
      if (!validate) {
        return required && !value && 'Required field'
      } else {
        return validate(value)
      }
    }

    return (
      <Field name={name} validate={inputValidation}>
        {({ field, form }) => {
          const { value } = field
          const { maxlength } = rest

          const customDesc = maxlength
            ? `${value?.length || 0} / ${maxlength}`
            : null

          return (
            <FormControl
              isInvalid={form.errors[name] && form.touched[name]}
              style={style}
            >
              {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
              <Textarea
                ref={ref}
                {...field}
                id={name}
                disabled={form.isSubmitting}
                {...rest}
              />
              <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
              {(customDesc || desc) && (
                <FormHelperText>{desc || customDesc}</FormHelperText>
              )}
            </FormControl>
          )
        }}
      </Field>
    )
  }
)

FormTexarea.defaultProps = defaultProps
FormTexarea.propTypes = propTypes

export default FormTexarea
