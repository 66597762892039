import * as R from 'ramda'
import api, { getPaginatedList } from 'shared/api'

export const doLoad = async props => {
  return await getPaginatedList('/admin/comments', {
    ...props,
  })
}

export const doApprove = async comments => {
  const ids = R.pluck('id', comments)
  await api.post('/admin/comments/approve', { ids })
}

export const doDelete = async ({ id }) => {
  await api.delete(`/admin/comments/${id}`)
}
