import * as R from 'ramda'
import React, { memo, useCallback } from 'react'
import styled from 'styled-components'
import update from 'immutability-helper'
import { useDrop } from 'react-dnd'
import { Card } from './Card'

const defaultProps = {}
const propTypes = {}

const Container = styled.div`
  padding: 10px;
`

const Empty = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  padding: 10px;
  text-align: center;
`

export const PickList = ({ list: cards, setList: setCards, updateStatus }) => {
  const findCard = useCallback(
    id => {
      const card = cards.filter(c => `${c.id}` === id)[0]
      return {
        card,
        index: cards.indexOf(card),
      }
    },
    [cards]
  )
  const moveCard = useCallback(
    (id, atIndex) => {
      const { card, index } = findCard(id)
      setCards(
        update(cards, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        })
      )
    },
    [findCard, cards, setCards]
  )
  const removeCard = id => {
    const { index } = findCard(id)
    setCards(R.remove(index, 1))
    updateStatus(id, false)
  }

  const [, drop] = useDrop(() => ({ accept: 'card' }))
  return (
    <Container ref={drop}>
      {R.isEmpty(cards) ? (
        <Empty>Empty list</Empty>
      ) : (
        cards.map(card => (
          <Card
            key={card.id}
            id={`${card.id}`}
            text={card.text}
            moveCard={moveCard}
            findCard={findCard}
            removeCard={removeCard}
          />
        ))
      )}
    </Container>
  )
}

PickList.defaultProps = defaultProps
PickList.propTypes = propTypes

export default memo(PickList)
