import * as R from 'ramda'
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import DatePicker from 'react-datepicker'
import { format } from 'date-fns'
import {
  Checkbox,
  Input,
  ButtonGroup,
  Button,
  PopoverBody,
  PopoverFooter,
  Tooltip,
  Flex,
} from '@chakra-ui/react'
import { MdAttachMoney, MdMoneyOff } from 'react-icons/md'
import { Select } from 'components/elements'

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  max-height: 30px;
  max-width: 30px;
  min-width: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.blue400};
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  text-transform: uppercase;
`
const UserAvatar = styled.img`
  width: 100%;
  height: 100%;
`
const UserDesc = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`
const UserInfo = styled.div`
  span {
    color: ${({ theme }) => theme.colors.gray500};
    font-size: 12px;
  }
`

export const CustomCell = props => {
  const { row: { original: { profile } = {} } = {} } = props

  return (
    <Flex>
      <AvatarWrapper>
        {profile?.profileImage ? (
          <UserAvatar src={profile?.profileImage} />
        ) : (
          R.head(profile?.firstName || profile?.username || 'U')
        )}
      </AvatarWrapper>
      <UserDesc>
        <UserInfo>
          {profile?.firstName} {profile?.lastName}{' '}
          <span>{profile?.username}</span>
        </UserInfo>
        {profile?.company && (
          <UserInfo>
            <span>
              {profile?.company} | {profile?.position}
            </span>
          </UserInfo>
        )}
      </UserDesc>
    </Flex>
  )
}

const StyledStatus = styled.div`
  font-weight: 600;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
  color: ${({ color, theme }) => theme.colors[color]};
  background-color: ${({ color, theme }) =>
    theme.getAlphaRgb(theme.colors[color], 0.08)};
  user-select: none;
`

const colorMap = {
  Approved: 'green400',
  Rejected: 'red400',
  default: 'yellow400',
}

export const StatusCell = props => {
  const { row: { original: { status, isFree, reviewerName } = {} } = {} } =
    props

  const color = colorMap[status] || colorMap.default

  return (
    <Flex d="column" align="center" justify="center">
      {reviewerName ? (
        <Tooltip label={reviewerName} hasArrow>
          <StyledStatus color={color}>
            {status || '-'} / {isFree ? 'Free' : 'Paid'}
          </StyledStatus>
        </Tooltip>
      ) : (
        <StyledStatus color={color}>
          {status || '-'} / {isFree ? 'Free' : 'Paid'}
        </StyledStatus>
      )}
    </Flex>
  )
}

const TimeCell = styled.div`
  font-size: 14px;
  text-align: center;
`

export const DateCell = props => {
  const { row: { original: { scheduledDate, publishedAt } = {} } = {} } = props

  return (
    <Flex direction="column">
      <TimeCell>
        {publishedAt
          ? format(new Date(publishedAt), 'MM/dd/yyyy hh:mm aa')
          : '-'}
      </TimeCell>
      <TimeCell>
        {scheduledDate
          ? format(new Date(scheduledDate), 'MM/dd/yyyy hh:mm aa')
          : '-'}
      </TimeCell>
    </Flex>
  )
}

const Label = styled.div`
  font-weight: 500;
  margin-bottom: 5px;
  ${({ theme }) => theme.font.weight.bold};
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.gray200};
    `}
`

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const statusOptions = [
  { label: 'Approved', value: 'Approved' },
  { label: 'Rejected', value: 'Rejected' },
]

export const ReviewForm = ({ original, updateStatus, close }) => {
  const { status: _status } = original
  const [status, setStatus] = useState(
    _status === 'Pending' ? 'Approved' : _status
  )
  const [updating, setUpdating] = useState(false)
  const [doSchedule, setSchedule] = useState(!R.isNil(original?.scheduledDate))
  const [scheduleDate, setScheduleDate] = useState(
    new Date(original?.scheduledDate) || null
  )

  const willApprove = status === 'Approved'

  useEffect(() => {
    if (!doSchedule) {
      setScheduleDate(null)
    }
  }, [doSchedule])

  useEffect(() => {
    if (status !== 'Approved') {
      setSchedule(false)
    }
  }, [status])

  return (
    <>
      <PopoverBody>
        <ReviewContainer>
          <Label>Status</Label>
          <Select
            value={status}
            onChange={e => setStatus(e.target.value)}
            options={statusOptions}
          />
          <Checkbox
            mt={4}
            mb={3}
            isChecked={doSchedule}
            isDisabled={!willApprove}
            onChange={e => {
              const willCheck = e.target.checked
              setSchedule(willCheck)
            }}
          >
            Schedule release
          </Checkbox>
          <Label disabled={!doSchedule}>Schedule for</Label>
          <DatePicker
            selected={scheduleDate}
            onChange={date => setScheduleDate(date)}
            disabled={!doSchedule}
            customInput={<Input />}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
          />
        </ReviewContainer>
      </PopoverBody>
      <PopoverFooter d="flex" justifyContent="flex-end">
        <ButtonGroup size="sm">
          <Button variant="outline" onClick={close}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            isLoading={updating}
            onClick={() => {
              setUpdating(true)
              updateStatus({
                ...original,
                status,
                scheduleDate,
              }).then(() => {
                close()
                setUpdating(false)
              })
            }}
          >
            Save
          </Button>
        </ButtonGroup>
      </PopoverFooter>
    </>
  )
}

export const StyledArticleOption = styled.div`
  background-color: ${({ theme }) => theme.colors.gray100};
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray300};
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.gray300};
    `}
`
const ArticleDesc = styled.div`
  margin-top: 10px;
  ${({ theme }) => theme.font.weight.bold};
  border-top: solid 1px ${({ theme }) => theme.colors.gray400};
  padding-top: 10px;
  width: 100%;
`
const ArticleDate = styled.div`
  margin-top: 10px;
  font-size: 12px;
  margin-left: auto;
  min-width: fit-content;
`

export const ArticleOption = ({ title, profile, publishedAt, ...rest }) => {
  return (
    <StyledArticleOption {...rest}>
      <Flex w="100%">
        <AvatarWrapper>
          {profile?.profileImage ? (
            <UserAvatar src={profile?.profileImage} />
          ) : (
            R.head(profile?.firstName || profile?.username)
          )}
        </AvatarWrapper>
        <UserDesc>
          <UserInfo>
            {profile?.firstName} {profile?.lastName}{' '}
            <span>{profile?.username}</span>
          </UserInfo>
          {profile?.company && (
            <UserInfo>
              <span>
                {profile?.company} | {profile?.position}
              </span>
            </UserInfo>
          )}
        </UserDesc>
        <ArticleDate>
          {format(new Date(publishedAt), 'MM/dd/yyyy hh:mm aa')}
        </ArticleDate>
      </Flex>
      <ArticleDesc>{title}</ArticleDesc>
    </StyledArticleOption>
  )
}

export const UserOption = ({
  profileImage,
  firstName,
  lastName,
  username,
  email,
  ...rest
}) => {
  return (
    <StyledArticleOption {...rest}>
      <Flex>
        <AvatarWrapper>
          {profileImage ? (
            <UserAvatar src={profileImage} />
          ) : (
            R.head(firstName || username)
          )}
        </AvatarWrapper>
        <UserDesc>
          <UserInfo>
            {firstName} {lastName} <span>{username}</span>
          </UserInfo>
          <UserInfo>
            <span>{email}</span>
          </UserInfo>
        </UserDesc>
      </Flex>
    </StyledArticleOption>
  )
}

export const NotFreeIcon = styled(MdAttachMoney)`
  path {
    &:first-child {
      display: none;
    }
  }
`

export const FreeIcon = styled(MdMoneyOff)`
  path {
    &:first-child {
      display: none;
    }
  }
`

const freeOptions = [
  { label: 'Free', value: 'true' },
  { label: 'Paid', value: 'false' },
]

export const FreeForm = ({ original, updateStatus, close }) => {
  const { isFree: _isFree } = original
  const [isFree, setIsFree] = useState(String(_isFree))
  const [updating, setUpdating] = useState(false)

  return (
    <>
      <PopoverBody>
        <ReviewContainer>
          <Label>Status</Label>
          <Select
            value={isFree}
            onChange={e => setIsFree(e.target.value)}
            options={freeOptions}
          />
        </ReviewContainer>
      </PopoverBody>
      <PopoverFooter d="flex" justifyContent="flex-end">
        <ButtonGroup size="sm">
          <Button variant="outline" onClick={close}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            isLoading={updating}
            onClick={() => {
              setUpdating(true)
              updateStatus({
                ...original,
                isFree,
              }).then(() => {
                close()
                setUpdating(false)
              })
            }}
          >
            Save
          </Button>
        </ButtonGroup>
      </PopoverFooter>
    </>
  )
}
