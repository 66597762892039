import * as R from 'ramda'
import React, { useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  Portal,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import * as L from 'layouts/ListLayout'
import { VscOpenPreview, VscEdit } from 'react-icons/vsc'
import { BsJournalCheck } from 'react-icons/bs'
import { useMenuBar } from 'context/MenuBar'

import {
  doLoad,
  doUpdateArticleStatus,
  doUpdateArticleFreeStatus,
} from '../modules/datasource'
import { getColumns } from '../modules/constants'

import UpdateAuthor from './UpdateAuthor'
import RemoveArticle from './RemoveArticleForm'

import * as S from './ArticlesList.styles'

const defaultProps = {}
const propTypes = {}

const PublishIcon = styled(BsJournalCheck)``

const UpdateStatusButton = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const modalMap = {
  updateAuthor: UpdateAuthor,
  deleteArticle: RemoveArticle,
}

const host = process.env.REACT_APP_BASE_URL

export const ArticlesList = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [modal, setModal] = useState(null)
  const [articleToDelete, setArticleToDelete] = useState(null)
  const [listState, setListState] = useState({})
  const { setActions } = useMenuBar()

  const { columnsConfig } = useMemo(() => {
    return getColumns()
  }, [])

  useEffect(() => {
    setActions([
      {
        label: 'Change article author',
        onClick: () => openModal('updateAuthor'),
      },
    ])
    return () => setActions([])
  }, [])

  const toolbarConfig = useMemo(() => {
    return {
      searchProps: {
        name: 'title',
        placeholder: 'Article title',
      },
      filters: [
        {
          name: 'status',
          type: 'select',
          label: 'Status',
          placeholder: 'All',
          options: [
            { label: 'Approved', value: 'Approved' },
            { label: 'Pending', value: 'Pending' },
            { label: 'Rejected', value: 'Rejected' },
          ],
          defaultValue: 'Pending',
        },
        {
          name: 'isFree',
          type: 'select',
          label: 'Free',
          placeholder: 'All',
          options: [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ],
        },
      ],
    }
  }, [])

  const load = async loadProps => {
    const tableState = await doLoad(loadProps)
    setListState(tableState)
    return tableState
  }
  const updateStatus = async props => {
    return doUpdateArticleStatus(props).then(() => {
      setListState(state => {
        const { list } = state
        const articleIndex = R.findIndex(
          R.propEq('articleId', props?.articleId),
          list
        )
        return {
          ...state,
          list: R.update(
            articleIndex,
            {
              ...props,
              scheduledDate: props?.scheduleDate,
            },
            list
          ),
        }
      })
    })
  }
  const updateFreeStatus = async props => {
    return doUpdateArticleFreeStatus(props).then(() => {
      setListState(state => {
        const { list } = state
        const articleIndex = R.findIndex(
          R.propEq('articleId', props?.articleId),
          list
        )

        return {
          ...state,
          list: R.update(
            articleIndex,
            {
              ...props,
              isFree: props?.isFree === 'true',
            },
            list
          ),
        }
      })
    })
  }

  const openModal = m => {
    setModal(m)
    onOpen()
  }

  const closeModal = () => {
    onClose()
    setModal(null)
  }

  const modalConfig = {
    onClose: closeModal,
    isOpen,
    setListState,
    articleToDelete,
  }

  const ModalComponent = modalMap[modal]

  return (
    <L.Container>
      <L.Content>
        <L.Panel>
          <L.Table
            fetchData={load}
            columns={columnsConfig}
            toolbarConfig={toolbarConfig}
            actions={{
              edit: {
                onClick: ({ original }) => {
                  const { articleId } = original
                  window.open(`${host}/articles/${articleId}/edit`, '_blank')
                },
                Icon: VscEdit,
                tooltipConfig: {
                  label: 'Edit on TSN',
                },
              },
              update: {
                onClick: () => {},
                Icon: ({ original }) => {
                  const [isOpen, setOpen] = useState(false)
                  const close = () => setOpen(false)

                  return (
                    <Popover
                      closeOnBlur
                      placement="left"
                      isOpen={isOpen}
                      onClose={close}
                    >
                      <PopoverTrigger>
                        <Tooltip label="Free status" hasArrow>
                          <UpdateStatusButton onClick={() => setOpen(true)}>
                            {original?.isFree ? (
                              <S.FreeIcon />
                            ) : (
                              <S.NotFreeIcon />
                            )}
                          </UpdateStatusButton>
                        </Tooltip>
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent w={353}>
                          <PopoverHeader fontWeight="semibold">
                            Review free status
                          </PopoverHeader>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          {isOpen && (
                            <S.FreeForm
                              original={original}
                              updateStatus={updateFreeStatus}
                              close={close}
                            />
                          )}
                        </PopoverContent>
                      </Portal>
                    </Popover>
                  )
                },
              },
              free: {
                onClick: () => {},
                Icon: ({ original }) => {
                  const [isOpen, setOpen] = useState(false)
                  const close = () => setOpen(false)

                  return (
                    <Popover
                      closeOnBlur
                      placement="left"
                      isOpen={isOpen}
                      onClose={close}
                    >
                      <PopoverTrigger>
                        <Tooltip label="Review" hasArrow>
                          <UpdateStatusButton onClick={() => setOpen(true)}>
                            <PublishIcon />
                          </UpdateStatusButton>
                        </Tooltip>
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent w={353}>
                          <PopoverHeader fontWeight="semibold">
                            Review article
                          </PopoverHeader>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          {isOpen && (
                            <S.ReviewForm
                              original={original}
                              updateStatus={updateStatus}
                              close={close}
                            />
                          )}
                        </PopoverContent>
                      </Portal>
                    </Popover>
                  )
                },
              },
              preview: {
                onClick: ({ original }) => {
                  const { slug } = original
                  window.open(`${host}/a/${slug}`, '_blank')
                },
                tooltipConfig: {
                  label: 'Preview',
                },
                Icon: () => <VscOpenPreview />,
                disabled: ({ original }) =>
                  !original.slug || !original.username,
              },
              deleteClick: ({ original }) => {
                openModal('deleteArticle')
                setArticleToDelete(original)
              },
            }}
            {...listState}
          />
        </L.Panel>
      </L.Content>
      {isOpen && <ModalComponent {...modalConfig} />}
    </L.Container>
  )
}

ArticlesList.defaultProps = defaultProps
ArticlesList.propTypes = propTypes

export default ArticlesList
