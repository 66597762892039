import * as R from 'ramda'
import api from '../api'

const getList = async (url, props) => {
  const {
    filters = [],
    //  sortBy = []
  } = props

  const _filters = R.map(
    ({ name, value }) => `${name}=${encodeURIComponent(value)}`,
    R.reject(({ value }) => !value, filters)
  ).join('&')

  // const sort = R.map(
  //   ({ id, desc }) => `&sort=${id}&sortDir=${desc ? 'DESC' : 'ASC'}`,
  //   sortBy
  // ).join('')

  const { data: list, ...rest } = await api.get(`${url}?${_filters}`)

  return {
    ...rest,
    list,
  }
}

const getPaginatedList = async (url, props = {}) => {
  const {
    page = 0,
    pageSize = 10,
    filters = [],
    customFilters = [],
    sortBy = [],
  } = props

  const _filters = R.map(
    ({ name, value }) => `${name}=${encodeURIComponent(value)}`,
    R.reject(({ value }) => !value, [...filters, ...customFilters])
  ).join('&')

  const sort = R.map(
    ({ id, desc }) => `&sort=${id}&sortDir=${desc ? 'DESC' : 'ASC'}`,
    sortBy
  ).join('')

  const {
    data: list,
    headers,
    ...rest
  } = await api.get(
    `${url}?page=${page + 1}&limit=${pageSize}&${_filters}${sort}`
  )

  const { 'x-total-results': total = 0 } = headers

  return {
    ...rest,
    headers,
    list,
    total,
  }
}

export { getList, getPaginatedList }
