import { DateCell } from '../components/CommentsList.styles'

export const getColumns = () => {
  const COLUMNS_CONFIG = [
    {
      Header: 'User',
      accessor: 'username',
      maxWidth: 90,
    },
    {
      Header: 'Comment',
      accessor: 'text',
      disableEllipsis: true,
    },
    {
      Header: 'Created / Updated',
      id: 'createdAt',
      Cell: DateCell,
      style: {
        maxWidth: 200,
        width: '100%',
      },
    },
    {
      Header: 'reviewed',
      id: 'reviewed',
      accessor: ({ reviewed }) => (reviewed ? 'Approved' : 'Pending'),
    },
  ]

  return { columnsConfig: COLUMNS_CONFIG }
}
