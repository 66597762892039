import React from 'react'
import { Formik, Form } from 'formik'
import Button from './Button'
import Input from './Input'
import Label from './Label'
import Select from './Select'
import Textarea from './Textarea'
import Upload from './Upload'

const FormComponent = ({ children, ...rest }) => {
  return (
    <Formik initialValues={{}} {...rest}>
      <Form>{children}</Form>
    </Formik>
  )
}

FormComponent.Button = Button

FormComponent.Input = Input

FormComponent.Label = Label

FormComponent.Select = Select

FormComponent.Textarea = Textarea

FormComponent.Upload = Upload

export default FormComponent
