import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  transition: all 0.3s;
  width: 17px;
  height: 17px;
  polyline,
  path {
    stroke: ${({ theme }) => theme.colors.grey};
    fill: transparent !important;
  }
`

export default function DeleteIcon() {
  return (
    <StyledSVG
      fill="none"
      height="24"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      viewBox="0 0 24 24"
      width="24"
    >
      <polyline points="3 6 5 6 21 6" />
      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
      <line x1="10" x2="10" y1="11" y2="17" />
      <line x1="14" x2="14" y1="11" y2="17" />
    </StyledSVG>
  )
}
