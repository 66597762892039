import tinycolor from 'tinycolor2'
import { css } from 'styled-components'

export const Ellipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`

export const getAlphaRgb = (color, alpha) =>
  tinycolor(color).setAlpha(alpha).toRgbString()
