import React from 'react'
import List from './List'

const Questions = () => ({
  index: true,
  path: '/questions',
  element: <List />,
})

export default Questions
