import * as R from 'ramda'
import React, { useState, useMemo } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  Portal,
  Tooltip,
} from '@chakra-ui/react'

import * as L from 'layouts/ListLayout'
import * as S from './UserList.styles'

import { VscOpenPreview } from 'react-icons/vsc'
import { useNavigate } from 'react-router-dom'
import { doLoad, doUpdatePhysicianStatus } from '../modules/datasource'
import { getColumns } from '../modules/constants'

const defaultProps = {}
const propTypes = {}

const host = process.env.REACT_APP_BASE_URL

export const UsersList = () => {
  const navigate = useNavigate()
  const [listState, setListState] = useState({})

  const { columnsConfig } = useMemo(() => {
    return getColumns()
  }, [])

  const toolbarConfig = useMemo(() => {
    return {
      searchProps: {
        name: 'emailOrUsername',
        placeholder: 'Email or Username',
      },
      filters: [
        {
          name: 'registeredPhysician',
          type: 'checkbox',
          label: 'Only registered as physicians',
        },
      ],
    }
  }, [])

  const load = async loadProps => {
    const tableState = await doLoad(loadProps)
    setListState(tableState)
    return tableState
  }

  const updateStatus = async props => {
    return doUpdatePhysicianStatus(props).then(() => {
      setListState(state => {
        const { list } = state
        const userIndex = R.findIndex(R.propEq('id', props?.id), list)
        return {
          ...state,
          list: R.update(
            userIndex,
            {
              ...props,
            },
            list
          ),
        }
      })
    })
  }

  return (
    <L.Container>
      <L.Content>
        <L.Panel>
          <L.Table
            fetchData={load}
            columns={columnsConfig}
            toolbarConfig={toolbarConfig}
            actions={{
              preview: {
                onClick: ({ original }) => {
                  const { username } = original
                  window.open(`${host}/p/${username}/`, '_blank')
                },
                Icon: () => <VscOpenPreview />,
                disabled: ({ original }) => !original.username,
                tooltipConfig: {
                  label: 'Preview',
                },
              },
              detailClick: ({ original }) => navigate(`/users/${original?.id}`),
              review: {
                onClick: () => {},
                Icon: ({ original }) => {
                  const [isOpen, setOpen] = useState(false)
                  const close = () => setOpen(false)

                  return (
                    <Popover
                      closeOnBlur
                      placement="left"
                      isOpen={isOpen}
                      onClose={close}
                    >
                      <PopoverTrigger>
                        <Tooltip label="Review" hasArrow>
                          <S.UpdateStatusButton onClick={() => setOpen(true)}>
                            <S.ReviewIcon />
                          </S.UpdateStatusButton>
                        </Tooltip>
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent w={353}>
                          <PopoverHeader fontWeight="semibold">
                            Review user physician status
                          </PopoverHeader>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          {isOpen && (
                            <S.ReviewForm
                              original={original}
                              updateStatus={updateStatus}
                              close={close}
                            />
                          )}
                        </PopoverContent>
                      </Portal>
                    </Popover>
                  )
                },
              },
            }}
            {...listState}
          />
        </L.Panel>
      </L.Content>
    </L.Container>
  )
}

UsersList.defaultProps = defaultProps
UsersList.propTypes = propTypes

export default UsersList
