import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem } from '@chakra-ui/react'

const StyledDiv = styled.div`
  ${({ current, theme }) => current && theme.font.weight.bold};
`
const StyledLink = styled(Link)`
  transition: color 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.blue500};
  }
`

const defaultProps = {
  items: [],
}
const propTypes = {}

export const BreadcrumbsComponent = ({ items }) => {
  return (
    <Breadcrumb>
      {items.map((item, key) => {
        const { label, href } = item
        const isLast = key === items.length - 1

        return (
          <BreadcrumbItem key={key}>
            {href ? (
              <StyledLink to={href}>{label}</StyledLink>
            ) : (
              <StyledDiv current={isLast}>{label}</StyledDiv>
            )}
          </BreadcrumbItem>
        )
      })}
    </Breadcrumb>
  )
}

BreadcrumbsComponent.defaultProps = defaultProps
BreadcrumbsComponent.propTypes = propTypes

export default BreadcrumbsComponent
