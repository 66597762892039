import React from 'react'
import styled, { css } from 'styled-components'
import { Table as ChakraTable, Checkbox } from '@chakra-ui/react'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.surface};
  background-clip: border-box;
  border-radius: 5px;
  padding: 0 0 26px;
  ${({ containerStyles }) => containerStyles && containerStyles};
`

const CustomTable = React.forwardRef((props, _ref) => {
  return <ChakraTable {...props} ref={_ref} />
})

export const Table = styled(CustomTable)`
  width: 100%;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  div::-webkit-scrollbar,
  ol::-webkit-scrollbar,
  pre::-webkit-scrollbar,
  span::-webkit-scrollbar,
  ul::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  div::-webkit-scrollbar-thumb,
  ol::-webkit-scrollbar-thumb,
  pre::-webkit-scrollbar-thumb,
  span::-webkit-scrollbar-thumb,
  ul::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.black300};
  }
`

export const THead = styled.thead`
  display: flex;
  width: 100%;
  max-width: -webkit-fill-available;
  padding: 0 0px;
  padding-right: 5px;
`

export const TH = styled.th`
  flex: 1;
  padding: 16px 10px;
  text-transform: uppercase;
  text-align: left;
  font-size: 12.35px;
  border-bottom: dashed 1px ${({ theme }) => theme.colors.green25};
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`

export const TBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0px;
  max-width: -webkit-fill-available;
  overflow-y: scroll;
`

export const TD = styled.td`
  flex: 1;
  padding: 16px 9px;
  border-bottom: dashed 1px ${({ theme }) => theme.colors.green25};
  display: flex;
  align-items: center;
  min-width: 0;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`

export const Cell = styled.div`
  ${({ theme, disableEllipsis }) => !disableEllipsis && theme.utils.ellipsis};
`

export const TR = styled.tr`
  display: flex;
  width: 100%;
  &:last-child {
    ${TD} {
      border-bottom: 0;
    }
  }
`

export const Footer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  padding: 0 30px;
`

export const DoubleCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  div {
    ${({ theme }) => theme.utils.ellipsis};
  }
`

export const CustomDiv = styled.div`
  line-height: 25px;
`

export const Badge = styled.div`
  color: ${({ color, theme }) => theme.colors[color] || color};
  background-color: #f1faff;
  padding: 6px 6px;
  border-radius: 5px;
  font-size: 13px;
`

export const BadgeStage = styled.div`
  font-weight: 600;
  font-size: 11px;
  background-color: #80808014;
  padding: 1px 1px;
  border-radius: 4px;
  text-align: center;
  color: ${({ isApproved }) => (isApproved ? '#50CD89' : '#588893')};
  width: 200px;
`

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 68px;
`

export const TableSubComponent = styled.div``

const ShowOverlayStyles = css`
  opacity: 0.8;
  z-index: 10;
`

const ExpandedOverlay = styled.div`
  background: ${({ theme }) => theme.colors.white};
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  position: absolute;
  transition: all 0.2s;
`

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px transparent;
  position: relative;
  margin-right: 15px;
  ${ExpandedOverlay} {
    ${({ locked }) => locked && ShowOverlayStyles};
  }
  ${({ expanded, theme }) =>
    expanded &&
    css`
      border-color: ${theme.colors.blue300};
      padding: 5px;
    `}
`

export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, checked, ...rest }, ref) => {
    return (
      <Checkbox
        isChecked={checked}
        isIndeterminate={indeterminate}
        {...rest}
        ref={ref}
      />
    )
  }
)
