import * as R from 'ramda'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import {
  useTable,
  usePagination,
  useFlexLayout,
  useExpanded,
  useSortBy,
  useRowSelect,
} from 'react-table'
import { Loading, Pagination } from 'components/elements'
import { getExpandAction, getTableActions } from './Actions'
import Toolbar from './Toolbar'
import * as S from './Table.styles'

import { Thead, Tr, Th, Td, chakra, Collapse } from '@chakra-ui/react'
import { FaSortDown, FaSortUp } from 'react-icons/fa'

const defaultProps = {
  list: [],
  pageSize: 10,
}
const propTypes = {}

export const Table = props => {
  const {
    columns,
    list,
    total,
    pageSize,
    className,
    containerStyles,
    toolbarConfig = {},
    actions,
    fetchData,
    disablePagination,
    renderSubComponent,
    customFilters,
    sortable,
    select,
    selectedRows,
    rowConfig,
  } = props
  const [hasMount, setHasMount] = useState(false)
  const [isFetching, setIsFetching] = useState(true)
  const [filters, _setFilters] = useState([])

  const data = useMemo(() => {
    return list
  }, [list])

  const tableColumns = useMemo(
    () => [
      ...columns,
      ...getTableActions(actions),
      ...getExpandAction(renderSubComponent),
    ],
    [columns]
  )

  const getRowId = useCallback(row => row.id, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageCount,
    gotoPage,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex: currentPage, sortBy, selectedRowIds },
    rows,
  } = useTable(
    {
      columns: tableColumns,
      data,
      initialState: {
        pageSize,
      },
      manualPagination: disablePagination || !R.isNil(total),
      pageCount: disablePagination ? -1 : Math.ceil(total / pageSize),
      manualSortBy: true,
      disableSortBy: !sortable,
      getRowId: select
        ? getRowId
        : (_, relativeIndex, parent) =>
            parent ? [parent.id, relativeIndex].join('.') : relativeIndex,
      autoResetSelectedRows: false,
    },
    useFlexLayout,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    hooks => {
      select &&
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            style: {
              padding: 0,
              paddingLeft: 14,
              minWidth: 39,
              maxWidth: 39,
            },
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <S.IndeterminateCheckbox
                {...getToggleAllPageRowsSelectedProps()}
                style={{ marginLeft: 5 }}
              />
            ),
            Cell: ({ row }) => (
              <S.RowWrapper>
                <Tr padding={1}>
                  <S.IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps()}
                  />
                </Tr>
              </S.RowWrapper>
            ),
          },
          ...columns,
        ])
    }
  )

  useEffect(async () => {
    setIsFetching(true)
    if (!hasMount) {
      const hasDefaultFilters = !R.isEmpty(
        R.filter(
          ({ defaultValue }) => !!defaultValue,
          toolbarConfig?.filters || []
        )
      )
      setHasMount(true)
      if (hasDefaultFilters) {
        return
      }
    }
    fetchData &&
      (await fetchData({
        page: currentPage,
        pageSize,
        filters,
        customFilters,
        sortBy,
      }))
    setIsFetching(false)
  }, [currentPage, filters, customFilters, sortBy])

  useEffect(() => {
    if (R.isEmpty(selectedRows) && !R.isEmpty(selectedFlatRows)) {
      toggleAllRowsSelected(false)
    }
  }, [selectedRows])

  useEffect(() => {
    select &&
      select(s => {
        const keys = R.keys(selectedRowIds)
        const concatedList = R.uniqBy(
          R.prop('id'),
          R.concat(selectedFlatRows, s)
        )

        return R.filter(i => R.includes(String(i?.id), keys), concatedList)
      })
  }, [selectedFlatRows])

  const handlePageClick = event => {
    gotoPage(event.selected)
  }

  const setFilters = props => {
    gotoPage(0)
    _setFilters(props)
  }

  return (
    <S.Container containerStyles={containerStyles}>
      <S.Table {...getTableProps()} className={className}>
        {!R.isEmpty(toolbarConfig || {}) && (
          <Toolbar
            config={{
              ...toolbarConfig,
              tableFilters: filters,
              setFilters,
            }}
          />
        )}
        <Thead paddingRight={15} style={{ marginRight: 10 }}>
          {headerGroups.map((headerGroup, tr) => (
            <Tr key={`header-tr-${tr}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, th) => {
                const columnProps = column.getHeaderProps(
                  column.getSortByToggleProps()
                )
                const { style } = columnProps
                return (
                  <Th
                    key={`th-${th}`}
                    {...columnProps}
                    style={column?.style || style}
                    isNumeric={column.isNumeric}
                    display="flex"
                    alignItems="center"
                    userSelect="none"
                  >
                    {column.render('Header')}
                    <chakra.span pl="4">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FaSortDown aria-label="sorted descending" />
                        ) : (
                          <FaSortUp aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                )
              })}
            </Tr>
          ))}
        </Thead>
        {isFetching ? (
          <S.LoadingContainer>
            <Loading />
          </S.LoadingContainer>
        ) : (
          <S.TBody {...getTableBodyProps()}>
            {rows.map((row, tr) => {
              prepareRow(row)
              return (
                <S.RowWrapper
                  key={`body-tr-${tr}`}
                  expanded={row.isExpanded}
                  locked={!row.isExpanded}
                >
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell, td) => {
                      const cellProps = cell.getCellProps()
                      const { style } = cellProps

                      return (
                        <Td
                          key={`td-${td}`}
                          {...cellProps}
                          style={cell?.column?.style || style}
                          isNumeric={cell.column.isNumeric}
                          display="flex"
                          alignItems={rowConfig?.alignItems || 'center'}
                        >
                          <S.Cell
                            disableEllipsis={cell?.column?.disableEllipsis}
                          >
                            {cell.render('Cell')}
                          </S.Cell>
                        </Td>
                      )
                    })}
                  </Tr>
                  {renderSubComponent && (
                    <Collapse startingHeight={0} in={row.isExpanded}>
                      <S.TableSubComponent>
                        {renderSubComponent({ row })}
                      </S.TableSubComponent>
                    </Collapse>
                  )}
                </S.RowWrapper>
              )
            })}
          </S.TBody>
        )}
      </S.Table>
      <S.Footer>
        <Pagination
          page={currentPage}
          pageCount={pageCount}
          onChange={handlePageClick}
        />
      </S.Footer>
    </S.Container>
  )
}

Table.defaultProps = defaultProps
Table.propTypes = propTypes

export default Table
