import * as R from 'ramda'
import { DateCell } from '../components/AnswersList.styles'
import { CustomCell } from 'routes/Articles/List/components/ArticlesList.styles'

const generateEllipsis = (text = '', maxSize) => {
  if (R.length(text) > maxSize) {
    return R.trim(R.take(maxSize, text)) + '...'
  }
  return text
}

export const getColumns = () => {
  const COLUMNS_CONFIG = [
    {
      Header: 'Author',
      accessor: 'articleId',
      Cell: CustomCell,
      style: {
        maxWidth: 270,
        width: '100%',
      },
    },
    {
      Header: 'Answer',
      id: 'answer',
      accessor: ({ text }) => generateEllipsis(text, 100),
      disableEllipsis: true,
    },
    {
      Header: 'Created / Updated',
      id: 'createdAt',
      Cell: DateCell,
      style: {
        maxWidth: 200,
        width: '100%',
      },
    },
  ]

  return { columnsConfig: COLUMNS_CONFIG }
}
