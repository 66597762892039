import React from 'react'
import {
  Box,
  Flex,
  Avatar,
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useColorModeValue,
  useColorMode,
  Image,
} from '@chakra-ui/react'
import { FaMoon, FaSun } from 'react-icons/fa'
import { MdOutlineLogout } from 'react-icons/md'
import { useMenuBar } from 'context/MenuBar'
import { useSession } from 'context/Session'

const defaultProps = {}
const propTypes = {}

export const Header = () => {
  const { toggleColorMode } = useColorMode()
  const text = useColorModeValue('dark', 'light')
  const SwitchIcon = useColorModeValue(FaMoon, FaSun)
  const { actions } = useMenuBar()
  const { user, clearSession } = useSession()
  const { profile = {} } = user
  const { profileImage } = profile

  const ProfileIcon = profileImage ? (
    <Image
      src={profileImage}
      alt={profile?.username}
      boxSize="30px"
      objectFit="cover"
      borderRadius="full"
      style={{ background: '#007CFF' }}
    />
  ) : (
    <Avatar size="sm" />
  )

  return (
    <Box
      w="100%"
      h="60px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p={3}
      borderBottom="1px"
      borderColor={useColorModeValue('gray.200', 'whiteAlpha.100')}
      background={useColorModeValue('white', 'gray.800')}
      position="sticky"
      top={0}
      zIndex={1}
    >
      <Flex>
        {actions && (
          <ButtonGroup>
            {actions &&
              actions.map(({ label, onClick, visible = true }, key) =>
                visible ? (
                  <Button key={key} onClick={onClick} variant="ghost">
                    {label}
                  </Button>
                ) : null
              )}
          </ButtonGroup>
        )}
      </Flex>
      <Flex>
        <IconButton
          size="md"
          fontSize="lg"
          aria-label={`Switch to ${text} mode`}
          variant="ghost"
          color="current"
          marginRight="2"
          onClick={toggleColorMode}
          icon={<SwitchIcon />}
        />
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={ProfileIcon}
            variant="ghost"
          />
          <MenuList>
            <MenuItem
              icon={<MdOutlineLogout size="20px" />}
              onClick={clearSession}
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  )
}

Header.defaultProps = defaultProps
Header.propTypes = propTypes

export default Header
