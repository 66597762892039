import React from 'react'
import { Outlet } from 'react-router-dom'
// import T from 'prop-types'

const defaultProps = {}
const propTypes = {}

export const AuthLayout = () => {
  return (
    <div>
      <Outlet />
    </div>
  )
}

AuthLayout.defaultProps = defaultProps
AuthLayout.propTypes = propTypes

export default AuthLayout
