import api from 'shared/api'

export const doLoad = async reportId => {
  return api.get(`/admin/reports/${reportId}`)
}

export const closeReport = async ({ reportId, values }) => {
  return api.put(`/admin/reports/${reportId}/close`, values)
}

export const deleteAdmin = async ({ articleId, values }) => {
  return api.delete(`/admin/articles/${articleId}`, { data: values })
}
